import copy from '@moohng/dan/lib/copy'
import config from '@/commons/config'
import * as util from '@/commons/utils'
import * as api from '@/services/api'

function plugin (Vue) {
  /* eslint-disable no-param-reassign */
  Vue.prototype.$config = config
  Vue.prototype.$api = api
  Vue.prototype.$util = util

  Vue.prototype.$copy = copy
  Vue.prototype.$device = util.getDevice()
  Vue.prototype.$isWeixin = function isWeixin () {
    const ua = window.navigator.userAgent
    return !!/MicroMessenger/i.test(ua)
  }
  Vue.prototype.$joinImg = (value) => util.joinPath(value, config.imgHost)

  // 过滤器
  // Vue.filter('dateFormat', function (value, fmt = 'YYYY-MM-DD HH:mm:ss') {
  //   return dayjs(value).format(fmt)
  // })
  Vue.filter('parseInt', parseInt)
  Vue.filter('parseFloat', parseFloat)
  Vue.filter('timestampFormat', util.timestampFormat)
  // Vue.filter('cut', cut)
  Vue.filter('joinImg', Vue.prototype.$joinImg)
  Vue.filter('lowerCase', util.toLowerCase)
  Vue.filter('upperCase', util.toUpperCase)

  Vue.filter('mul', (...args) => {
    return util.Decimal.mul(...args)
  })
  Vue.filter('add', (...args) => {
    return util.Decimal.add(...args)
  })
  Vue.filter('div', (...args) => {
    return util.Decimal.div(...args)
  })
  Vue.filter('sub', (...args) => {
    return util.Decimal.sub(...args)
  })

  // 自定义指令 图片
  Vue.directive('src', (el, { value, oldValue }) => {
    if (value !== oldValue) {
      if (el instanceof Image) {
        el.src = value
      } else {
        el.style.backgroundImage = `url(${value})`
      }
    }
  })

  const resetDiv = () => {
    setTimeout(() => {
      const scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0
      window.scrollTo(0, Math.max(scrollHeight - 1, 0))
    }, 100)
  }
  Vue.directive('blur', {
    inserted (el) {
      el.addEventListener('blur', resetDiv)
    },
    unbind (el) {
      el.removeEventListener('blur', resetDiv)
    },
  })

  Vue.directive('load', {
    bind (el, { value }) {
      el.ob = new IntersectionObserver(entries => {
        entries.forEach($item => {
          if ($item.intersectionRatio > 0) {
            value()
          }
        })
      })
      el.ob.observe(el)
    },
    unbind (el) {
      el.ob.unobserve(el)
    },
  })
}

export default plugin
