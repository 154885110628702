<template>
  <div class="coin-dialog">
    <div class="padding-lr-lg padding-tb flex align-center justify-between solid-bottom">
      <span>选择主网</span>
      <a @click="onClose">
        <icon type="close"></icon>
      </a>
    </div>
    <div class="list">
      <div class="item padding-lr-xs flex align-center" v-for="(network, index) in networkList" :key="network.id" @click="onSelectNetwork(network, index)">
        <div v-if="network.icon" class="coin-icon bg-img margin-right round" v-src="network.icon"></div>
        <div class="upper-case">
          <div>{{network.name}}</div>
          <div class="contract">{{network.title}}</div>
        </div>
        <div class="select-icon" :class="{ selected: networkIndex === index }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    options: Object,
    current: Object,
  },
  computed: {
    ...mapState(['network']),
    networkList () {
      return this.options?.networks || this.network?.networks
    },
    networkIndex () {
      return this.networkList.findIndex(({ id }) => id === this.current.id)
    },
  },
  methods: {
    onSelectNetwork (network, index) {
      this.$emit('select', network, index)
    },
    onClose () {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.coin-dialog {
  padding-top: 6px;
  width: 317px;
  background-color: var(--dialog-bg);
  border-radius: 10px;
  .list {
    padding-bottom: 20px;
    max-height: 400px;
    overflow: auto;
  }
  .item {
    padding: 18px 18px;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      background-color: var(--hover-bg);
    }
    .contract {
      font-size: 12px;
      opacity: 0.38;
    }
  }
  .coin-icon {
    width: 23px;
    height: 23px;
    background-color: transparent;
  }
  .select-icon {
    margin-left: auto;
    width: 24px;
    height: 24px;
    border: 5px solid #D8D8D8;
    border-radius: 50%;
    &.selected {
      border-width: 0;
      background-color: #628FF3;
    }
  }
}
.solid-bottom {
  border-color: #363636;
}
</style>
