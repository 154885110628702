import Vue from 'vue'
import VueRouter from 'vue-router'
import { getTheme } from '@/commons/utils'
import Home from '@/pages/Home/Index'
import TabBar from '@/pages/commons/TabBar'
import ModulePool from '@/pages/Home/ModulePool'
import ModuleSwap from '@/pages/Home/ModuleSwap'
import AddLiquidity from '@/pages/Home/AddLiquidity'
import CreateLiquidity from '@/pages/Home/CreateLiquidity'
import DecLiquidity from '@/pages/Home/DecLiquidity'
import DestoryLiquidity from '@/pages/Home/destroyLiquidity'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: {
      name: 'ModuleSwap',
    },
    children: [
      {
        path: 'swap',
        name: 'ModuleSwap',
        components: {
          tab: TabBar,
          default: ModuleSwap,
        },
      },
      {
        path: 'pool',
        name: 'ModulePool',
        components: {
          tab: TabBar,
          default: ModulePool,
        },
      },
      {
        path: 'liquidity/add',
        name: 'AddLiquidity',
        component: AddLiquidity,
      },
      {
        path: 'liquidity/create',
        name: 'CreateLiquidity',
        component: CreateLiquidity,
      },
      {
        path: 'liquidity/',
        name: 'DecLiquidity',
        component: DecLiquidity,
      },
      {
        path: 'liquidity/destory',
        name: 'destoryLiquidity',
        component: DestoryLiquidity,
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return {
      x: 0,
      y: 0,
    }
  },
})

router.beforeEach((to, from, next) => {
  next()

  // 设置主题
  document.title = to.meta.title ?? process.env.VUE_APP_TITLE ?? ''
  document.body.dataset.duiTheme = to.meta.theme ?? getTheme()
})

export default router
