<template>
  <div class="confirm-dialog text-center text-md">
    <div class="title text-lg" v-if="title">{{title}}</div>
    <div class="content">
      <slot>{{content}}</slot>
    </div>
    <div class="solid-top">
      <a class="button" @click="$emit('cancel')">{{cancelText}}</a>
      <a class="button text-blue solid-left" @click="$emit('confirm')">{{confirmText}}</a>
    </div>
  </div>
</template>

<script>
import i18n from '../../i18n'

export default {
  name: 'confirm-dialog',
  props: {
    title: String,
    content: String,
    cancelText: {
      type: String,
      default: i18n.t('cancel'),
    },
    confirmText: {
      type: String,
      default: i18n.t('confirm'),
    },
  },
}
</script>

<style lang="scss" scoped>
.confirm-dialog {
  padding-top: 20px;
  width: 317px;
  background-color: var(--dialog-bg);
  border-radius: 10px;
  .title {
    margin: 10px 30px 20px;
  }
  .content {
    margin: 20px 30px 40px;
  }
  .button {
    display: inline-block;
    width: 50%;
    padding: 15px;
  }
}
.solid-left,
.solid-top {
  border-color: #d8d8d8;
}
.text-blue {
  color: #3A70DD;
}
</style>
