import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    globalConfig: null,
    profile: null,
    coinList: [], // 币种列表
    coinLPList: [], // 币种列表
    myPoolList: [],
    myAssetList: [],
    myAssetDetail: null,
    allAssetLog: null,
    rechargeAssetLog: null,
    withdrawAssetLog: null,
    poolLog: null,
    airdropLog: null,
    walletAddress: null,
    network: null,
    networkLP: null,
    friend: null,
    contract: null, // 合约申请列表
  },
  getters: {
    fee: state => state.globalConfig?.fee,
    airdrop_fee: state => state.globalConfig?.airdrop_fee,
    address: state => state.walletAddress?.address,
  },
  mutations: {
    SET_GLOBAL_CONFIG (state, data) {
      state.globalConfig = data
    },
    SET_PROFILE (state, data) {
      state.profile = data
    },
    SET_COIN_LIST (state, data) {
      state.coinList = data
    },
    SET_COIN_LP_LIST (state, data) {
      state.coinLPList = data
    },
    SET_POOL_LIST (state, data) {
      state.myPoolList = data
    },
    SET_ASSET_LIST (state, data) {
      state.myAssetList = data
    },
    SET_ASSET_DETAIL (state, data) {
      state.myAssetDetail = data
    },
    SET_ALL_ASSET_LOG (state, data) {
      state.allAssetLog = {
        ...state.allAssetLog,
        ...data,
      }
    },
    SET_RECHARGE_ASSET_LOG (state, data) {
      state.rechargeAssetLog = {
        ...state.rechargeAssetLog,
        ...data,
      }
    },
    SET_WITHDRAW_ASSET_LOG (state, data) {
      state.withdrawAssetLog = {
        ...state.withdrawAssetLog,
        ...data,
      }
    },
    SET_POOL_LOG (state, data) {
      state.poolLog = {
        ...state.poolLog,
        ...data,
      }
    },
    SET_AIRDROP_LOG (state, data) {
      state.airdropLog = {
        ...state.airdropLog,
        ...data,
      }
    },
    SET_WALLET_ADDRESS (state, data) {
      state.walletAddress = data
    },
    SET_NETWORK (state, data) {
      state.network = data
    },
    SET_NETWORK_LP (state, data) {
      state.networkLP = data
    },
    SET_FRIEND (state, data) {
      state.friend = {
        ...state.friend,
        ...data,
      }
    },
    SET_CONTRACT_LIST (state, data) {
      state.contract = {
        ...state.contract,
        ...data,
      }
    },
  },
  actions: {
    getPoolNetwork ({ commit }, data) {
      return api.getPoolNetwork(data).then(({ data }) => {
        commit('SET_NETWORK', data)
      })
    },
  },
  modules: {},
})
