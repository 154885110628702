<template>
  <div class="add-liq">
    <div class="card">
      <nav-bar>{{$t('yourLiq')}}</nav-bar>
      <div class="item">
        <div class="text-center text-sm">{{$t('rate')}}</div>
        <div class="text-center text-sm" style="text-decoration: underline">{{value}}%</div>
        <div style="padding-left: 20px;padding-right: 20px"><van-slider v-model="value" @change="onChange" style="margin-top: 20px"/></div>

        <div class="flex_h_around" style="margin-top: 20px">
          <div class="flex1 rate_btn" @click="onChange(25)">25%</div>
          <div class="flex1 rate_btn" @click="onChange(50)">50%</div>
          <div class="flex1 rate_btn" @click="onChange(75)">75%</div>
          <div class="flex1 rate_btn" @click="onChange(100)">100%</div>
        </div>
      </div>
      <div class="item">
        <div class="label">{{$t('lp')}}</div>
        <div class="flex justify-between align-center padding-lr-sm">
          <input class="num margin-lr-xs" v-model="lpInput" placeholder="0" @input="onInput('lp', $event)"/>
        </div>
      </div>
      <div class="text-center text-sm upper-case" v-show="lp !== null">可用：{{lp}}</div>
      <div class="item">
        <div class="label">{{from.contract_origin}}</div>
        <div class="flex justify-between align-center padding-lr-sm">
          <input class="num margin-lr-xs" v-model="fromInput" placeholder="0.0" @input="onInput('fromInput', $event)" />
        </div>
      </div>
      <div class="text-center text-sm upper-case" v-show="fromAmount !== null">可用：{{fromAmount}} {{from.name}}</div>
      <div class="text-center">
        <icon type="add"></icon>
      </div>
      <div class="item">
        <div class="label">{{to.contract_origin}}</div>
        <div class="flex justify-between align-center padding-lr-sm">
          <input class="num margin-lr-xs" v-model="toInput" placeholder="0.0" @input="onInput('toInput', $event)" />
        </div>
      </div>
      <div class="text-center text-sm upper-case" v-show="toAmount !== null">可用：{{toAmount}} {{to.name}}</div>
    </div>

    <!-- <div v-show="showQa" class="padding-lr margin-tb-sm flex justify-between text-md">
      <span class="text-gray upper-case">QA数量</span>
      <span>{{qaSaleAmount}}</span>
    </div> -->

    <div class="footer" v-if="authorizeFlagC">
      <button class="dui-button block bg-blue" @click="onSubmit">{{$t('reduceLiq')}}</button>
    </div>
    <div class="footer" v-else>

      <button class="dui-button block bg-blue" @click="doAuthorize"><img :src="require('../../assets/images/loading.gif')" alt="" v-if="loadingA" class="loading"/>{{$t('authorize')}} Liquidity provider</button>
    </div>
  </div>
</template>

<script>
import { Decimal } from '@/commons/utils'
import liquidity from '../commons/mixins/liquidity'
import { ethers } from 'ethers'
import { GLOBAL_CONFIGS } from '@/commons/const'
export default {
  data () {
    return {
      from: {
        network: GLOBAL_CONFIGS.network,
      },
      to: {
        network: GLOBAL_CONFIGS.network,
      },
      pairContract: null, // 交易对地址
      myPoolList: [],
      lp: '', // 不知道如何描述
      lpInput: '',
      inputRate: 0,
      authorizeFlagC: false,
      loadingA: false,
      value: 0,
    }
  },
  created () {
    this.init()
  },
  mixins: [liquidity],
  methods: {
    // 获取交易对池总量， 计算比例
    async getRate () {
      // 获取自己的lp TODO: 这里不需要计算精度
      const [, banlance] = await this.toAsync(this.pairContract.balanceOf(this.address))
      const hex2 = ethers.utils.hexValue(banlance)
      const Value2 = this.hex2int(hex2)
      this.lp = Value2
      // 获取总的lg
      const [, totalSupply] = await this.toAsync(this.pairContract.totalSupply())
      const hex3 = ethers.utils.hexValue(totalSupply)
      const Value3 = this.hex2int(hex3)
      this.rate = Decimal.div(Value2, Value3)
      // 计算amount
      const { fromAmount, toAmount } = await this.getLiquidityAmount(
        this.from,
        this.to,
        this.fromDecimals,
        this.toDecimals,
      )
      this.fromAmount = this.calcAmount(fromAmount, this.rate, this.fromDecimals)
      this.toAmount = this.calcAmount(toAmount, this.rate, this.toDecimals)
      this.baseAsset = fromAmount
      this.timer = setTimeout(() => {
        this.getRate()
      }, 10000)
    },
    calcAmount (Value0, rate, decimal) {
      let amount = Decimal.mul(Value0, rate)
      amount = amount.toFixed(decimal)
      return amount
    },
    // 调用pair合约的授权方法对路由地址进行授权
    async doAuthorize () {
      if (this.loadingA) {
        return
      }
      if (this.authorizeFlagC) {
        return
      }
      const amount = this.lpInput
      const contract = this.pairContract
      if (amount === '') {
        this.$toast('请输入需要授权的lp数量')
        return
      }
      this.loadingA = true
      const status = await this.checkAuthorize(this.pairContract, this.routerAddress, 0, this.lpInput)
      if (status) {
        this.transationCallback()
      } else {
        await this.authorize(amount, 0, contract, this.routerAddress, this.transationCallback)
      }
    },
    // 区块链确认回调
    transationCallback () {
      this.authorizeFlagC = true
      this.loadingA = false
    },
    // 减少流动性
    async onSubmit (value) {
      if (this.fromInput === '' && this.toInput === '') {
        this.$toast('请输入数量')
        return
      }
      // 计算数量
      const fromDecimals = this.fromDecimals
      const toDecimals = this.toDecimals
      // const fromAmount = Decimal.mul(this.fromInput, ethers.BigNumber.from(10).pow(fromDecimals)).valueOf()
      // const toAmount = Decimal.mul(this.toInput, ethers.BigNumber.from(10).pow(toDecimals)).valueOf()
      let fromAmount = 0
      let toAmount = 0
      if (this.isQa === 'from') {
        fromAmount = await this.getQaSaleAmount(this.fromContract, this.fromDecimals, this.fromInput)
        toAmount = Decimal.mul(this.toInput, ethers.BigNumber.from(10).pow(toDecimals))
      } else if (this.isQa === 'to') {
        fromAmount = Decimal.mul(this.fromInput, ethers.BigNumber.from(10).pow(fromDecimals))
        toAmount = await this.getQaSaleAmount(this.toContract, this.toDecimals, this.toInput)
      } else {
        fromAmount = Decimal.mul(this.fromInput, ethers.BigNumber.from(10).pow(fromDecimals))
        toAmount = Decimal.mul(this.toInput, ethers.BigNumber.from(10).pow(toDecimals))
      }
      // 获取当前时间戳
      const timeStr = (Date.now()).toString().substring(0, 10)
      // 定义参数
      const tokenA = this.from.contract_origin
      const tokenB = this.to.contract_origin

      // 方差计算，没有这个提交就会失败
      const amountAMin = String(Decimal.mul(fromAmount, Decimal.sub(1, this.toleranceValue))).split('.')[0]
      const amountBMin = String(Decimal.mul(toAmount, Decimal.sub(1, this.toleranceValue))).split('.')[0]

      const liquidity = String(this.lpInput).split('.')[0]
      const to = this.address
      const deadline = parseInt(timeStr) + 600
      let response
      if (tokenA === GLOBAL_CONFIGS.upperSymbol || tokenB === GLOBAL_CONFIGS.upperSymbol) {
        const isFrom = tokenA === GLOBAL_CONFIGS.upperSymbol
        const token = isFrom ? tokenB : tokenA
        const amountTokenDesired = isFrom ? amountBMin : amountAMin
        const amountQKIMin = isFrom ? amountAMin : amountBMin
        const gasLimit = await this.getEstimateGas(() => this.routerConstract.estimateGas.removeLiquidityQKI(token, liquidity, amountTokenDesired, amountQKIMin, to, deadline))
        if (gasLimit === 0) {
          return
        }
        response = await this.toAsync(this.routerConstract.removeLiquidityQKI(token, liquidity, amountTokenDesired, amountQKIMin, to, deadline, {
          gasLimit,
          gasPrice: ethers.utils.parseUnits(String(this.gasPrice), 'gwei'),
        }))
      } else {
        const gasLimit = await this.getEstimateGas(() => this.routerConstract.estimateGas.removeLiquidity(tokenA, tokenB, liquidity, amountAMin, amountBMin, to, deadline))
        if (gasLimit === 0) {
          return
        }
        response = await this.toAsync(this.routerConstract.removeLiquidity(tokenA, tokenB, liquidity, amountAMin, amountBMin, to, deadline, {
          gasLimit,
          gasPrice: ethers.utils.parseUnits(String(this.gasPrice), 'gwei'),
        }))
      }
      const [error, res] = response
      if (this.doResponse(error, res)) {
        this.$toast('提交请求成功，等待区块确认')
        this.queryTransation(res.hash, () => {
          this.fromInput = ''
          this.toInput = ''
          // 重新获取lp的值
          this.getRate()
        })
      }
    },
    onInput (keyName, e) {
      let rate = 0
      const val = e.target.value
      if (keyName === 'lp') {
        if (this.lp !== 0 && val <= this.lp) {
          rate = Decimal.mul(Decimal.div(val, this.lp), 100)
          this.onChange(Number(rate.valueOf()))
        }
      } else if (keyName === 'fromInput') {
        if (this.lp !== 0 && val <= this.fromAmount) {
          rate = Decimal.mul(Decimal.div(val, this.fromAmount), 100)
          this.onChange(Number(rate.valueOf()))
        }
      } else if (keyName === 'toInput') {
        if (this.lp !== 0 && val <= this.toAmount) {
          rate = Decimal.mul(Decimal.div(val, this.toAmount), 100)
          this.onChange(Number(rate.valueOf()))
        }
      }
    },
    calcInputValue (keyName, rate) {
      if (keyName === 'lp') {
        this.fromInput = this.calcAmount(this.fromAmount, rate, this.fromDecimals)
        this.toInput = this.calcAmount(this.toAmount, rate, this.toDecimals)
      } else if (keyName === 'fromInput') {
        this.lpInput = this.calcAmount(this.lp, rate, 0)
        this.toInput = this.calcAmount(this.toAmount, rate, this.toDecimals)
      } else if (keyName === 'toInput') {
        this.lpInput = this.calcAmount(this.lp, rate, 0)
        this.fromInput = this.calcAmount(this.fromAmount, rate, this.fromDecimals)
      }
      if (this.isQa === 'from') {
        this.getQaSaleAmount(this.fromContract, this.fromDecimals, this.fromInput)
      } else if (this.isQa === 'to') {
        this.getQaSaleAmount(this.toContract, this.toDecimals, this.toInput)
      }
    },
    onChange (value) {
      this.value = value
      const rate = Decimal.div(value, 100)
      this.calcInputValue('lp', rate)
      this.calcInputValue('fromInput', rate)
      this.calcInputValue('toInput', rate)
    },
  },
  destroyed () {
    clearTimeout(this.timer)
  },
}
</script>

<style lang="scss" scoped>
.card {
  margin: 16px 10px;
  padding-bottom: 15px;
  background-color: var(--card-bg2);
  border-radius: 10px;
}
.item {
  margin: 6px 10px;
  padding: 16px 4px;
  background-color: var(--card-bg);
  border-radius: 10px;
}
.label {
  margin-left: 18px;
  color: var(--num-color);
  font-size: 12px;
}
.num {
  width: 100px;
  flex: 0px 1 1;
  font-size: 35px;
}
.icon-add {
  width: 10px;
  height: 12px;
}
.footer {
  margin: 100px 10px 10px;
  .dui-button {
    height: 55px;
    font-size: 16px;
    border-radius: 10px;
  }
}
body[data-dui-theme="dark"] {
  .item {
    --card-bg: #2B2D61;
  }
}
.item1 {
  display: block;
  margin: 12px 10px;
  padding-top: 30px;
  padding-bottom: 18px;
  // height: 80px;
  background: var(--card-bg2);
  box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 0.0299388);
  border-radius: 10px;
  font-size: 16px;
  .label {
    position: absolute;
    right: 0;
    bottom: 90%;
    font-size: 12px;
    color: #979ab6;
    white-space: nowrap;
  }
  .contract {
    font-size: 12px;
    color: #979ab6;
  }
}
.rate_btn{
  background-color: #fff;
  border-radius: 5px;
  margin-left: 20px;
  color: #333;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.rate_btn:last-child{
  margin-right: 20px;
}
</style>
