import Vue from 'vue'
import Big from 'big.js'
import { ethers } from 'ethers'
import { APP_TOKEN, APP_THEME, TOLERANCE_VALUE, REG_INPUT_NUMBER, GLOBAL_CONFIGS } from './const'

export function getToken () {
  return window.localStorage.getItem(APP_TOKEN)
}

export function setToken (token) {
  if (!token) {
    return window.localStorage.removeItem(APP_TOKEN)
  }
  return window.localStorage.setItem(APP_TOKEN, token)
}

export function joinPath (url, host) {
  if (/(http|https):\/\/([\w.]+\/?)\S*/.test(url)) {
    return url
  }
  if (url && host) {
    return `${host.replace(/\/+$/, '')}/${url.replace(/^\/+/, '')}`
  }
  return url
}

export function timestampFormat (timestamp, fmt = 'DD天 HH小时 mm分 ss秒') {
  const perSecond = 1000
  const perMinute = 60 * perSecond
  const perHour = 60 * perMinute
  const perDay = 24 * perHour

  const days = Math.floor(timestamp / perDay)
  const hours = Math.floor((timestamp - days * perDay) / perHour)
  const minutes = Math.floor((timestamp - days * perDay - hours * perHour) / perMinute)
  const seconds = Math.floor((timestamp - days * perDay - hours * perHour - minutes * perMinute) / perSecond)

  return fmt.replace(/DD|HH|mm|ss/g, (matched) => {
    switch (matched) {
      case 'DD':
        return String(days).padStart(2, '0')
      case 'HH':
        return String(hours).padStart(2, '0')
      case 'mm':
        return String(minutes).padStart(2, '0')
      case 'ss':
        return String(seconds).padStart(2, '0')
      default:
        return ''
    }
  })
}

export function splitBy (arr, count) {
  if (!Array.isArray(arr)) {
    return arr
  }
  const results = []
  let temp = []
  arr.forEach((item, index) => {
    temp = temp.concat(item)
    if (!(index + 1) % count) {
      results.push(temp)
      temp = []
    }
  })
  if (temp.length) {
    results.push(temp)
  }
  return results
}

export function toLowerCase (str, defaultValue = '') {
  return str ? String.prototype.toLocaleLowerCase.call(str) : defaultValue
}

export function toUpperCase (str, defaultValue = '') {
  return str ? String.prototype.toLocaleUpperCase.call(str) : defaultValue
}

export function getDevice () {
  const ua = window.navigator.userAgent
  if (/iPhone|iPad|iPod|iOS/i.test(ua)) {
    return 'ios'
  }
  if (/Android/i.test(ua)) {
    return 'android'
  }
  return 'pc'
}

export function toast (text) {
  const vm = new Vue()
  return vm.$toast(text)
}

export function toImage (img) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.crossOrigin = 'Anonymous'
    image.onload = function onload () {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      canvas.getContext('2d').drawImage(image, 0, 0)
      const src = canvas.toDataURL('image/jpeg')
      resolve(src)
    }
    image.onerror = reject
    image.src = img
  })
}

export function checkWebp () {
  try {
    return document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0
  } catch (err) {
    //
  }
  return false
}

export function toggleTheme (theme) {
  if (!theme) {
    const currentTheme = getTheme()
    theme = currentTheme === 'light' ? 'dark' : 'light'
  }
  document.body.dataset.duiTheme = theme
  localStorage.setItem(APP_THEME, theme)
  return theme
}

export function getTheme () {
  return localStorage.getItem(APP_THEME) ?? process.env.VUE_APP_THEME ?? 'light'
}

export function getTolerance () {
  return localStorage.getItem(TOLERANCE_VALUE) ?? process.env.VUE_APP_TOLERANCE ?? 0.01
}

export function inputNumber (val = '') {
  return val.match(REG_INPUT_NUMBER)[0]
}

Big.DP = 18
Big.NE = -19

window.Big = Big

export const Decimal = {
  add (a, b) {
    try {
      return Big(a).add(Big(b))
    } catch {
    }
  },
  sub (a, b) {
    try {
      return Big(a).sub(Big(b))
    } catch {
    }
  },
  mul (a, b) {
    try {
      return Big(a).mul(Big(b))
    } catch {
    }
  },
  div (a, b) {
    try {
      return Big(a).div(Big(b))
    } catch {
    }
  },
}

// 复制功能
export const h5Copy = {
  methods: {
    h5Copy (content, msg) {
      if (!document.queryCommandSupported('copy')) {
        // 不支持
        return false
      }
      const textarea = document.createElement('textarea')
      textarea.value = content
      textarea.readOnly = 'readOnly'
      document.body.appendChild(textarea)
      textarea.select() // 选择对象
      textarea.setSelectionRange(0, content.length) // 核心
      document.execCommand('copy') // 执行浏览器复制命令
      textarea.remove()
      toast('复制成功！')
    },
  },
}

// 表单验证
export const vertify = {
  methods: {
    // 验证电话号码
    isPhoneNumber (phone) {
      if (!(/^1[3456789]\d{9}$/.test(phone))) {
        return false
      }
      return true
    },
    // 验证正整数
    isAllNumber (number) {
      if ((/^\d{1,}$/.test(number))) {
        return false
      }
      return true
    },
    // 验证身份证
    isIdCards (ids) {
      var idcardReg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
      if (idcardReg.test(ids)) {
        return false
      }
      return true
    },
    // 验证英文和数字组合
    isEnAndNumber (str) {
      var reg = /^[a-zA-Z0-9]+$/
      if (reg.test(str)) {
        return false
      }
      return true
    },
    // 验证带小数点的数字
    isFloatNumber (str) {
      var reg = /^([1-9]\d*\.?\d*)|(0\.\d*[1-9])$/
      if (reg.test(str)) {
        return false
      }
      return true
    },
  },
}

// 精度计算
export const precisionCalc = {
  methods: {
    // 乘法函数，用来得到精确的乘法结果
    accMul (arg1, arg2) {
      var m = 0
      m += this.deal(arg1)
      m += this.deal(arg2)
      var r1 = Number(arg1.toString().replace('.', ''))
      var r2 = Number(arg2.toString().replace('.', ''))
      return (r1 * r2) / Math.pow(10, m)
    },
    // 求小数点后的数据长度
    deal (arg) {
      var t = 0
      try {
        t = arg.toString().split('.')[1].length
      } catch (e) {
        console.log(e)
      }
      return t
    },
  },
}

// 初始化智能合约
export const initEth = {
  data () {
    return {
      provider: {},
      signer: {},
      ethProvider: {},
      ethSigner: {},
      chainId: 0,
      address: '', // 当前钱包的地址
    }
  },
  async created () {
    if (typeof window.getPrivateKey === 'undefined') {
      if (typeof ethereum === 'undefined') {
        // toast('请安装metamask插件、或者使用qkpay打开')
        await this.waitInject()
      } else {
        window.ethereum.enable()
        const customHttpProvider = new ethers.providers.Web3Provider(window.ethereum)

        if (window.ethereum.isMetaMask) {
          window.ethereum
            .request({
              method: 'net_version',
            })
            .then((chainId) => {
            // 可以把
              if (chainId !== GLOBAL_CONFIGS.chainId) {
                toast('请使用' + GLOBAL_CONFIGS.network + '主网,请切换到' + GLOBAL_CONFIGS.network + '主网')
              }
              this.chainId = chainId
            })
            .catch((error) => {
            // If the request fails, the Promise will reject with an error.
              console.log(error)
            })
        }
        window.ethereum.on('chainChanged', (chainId) => {
        // Handle the new chain.
        // Correctly handling chain changes can be complicated.
        // We recommend reloading the page unless you have a very good reason not to.
          if (chainId !== GLOBAL_CONFIGS.chainIdHex) {
            toast('请使用' + GLOBAL_CONFIGS.network + '主网')
          }
          setTimeout(function () {
          // window.location.reload()
          }, 2500)
        })

        this.provider = customHttpProvider
        this.signer = customHttpProvider.getSigner()
      }
    } else {
      const privateKey = window.getPrivateKey()
      this.provider = new ethers.providers.JsonRpcProvider({ url: 'https://hz.node.quarkblockchain.cn' })
      this.signer = new ethers.Wallet(privateKey, this.provider)
    }

    await this.getAddress()
    // }
  },
  methods: {
    // 等待android注入结果
    async waitInject () {
      clearInterval(this.waitInjectTimer)
      if (typeof window.getPrivateKey === 'undefined') {
        this.waitInjectTimer = setTimeout(() => {
          this.waitInject()
        }, 1000)
      } else {
        clearInterval(this.waitInjectTimer)
        const privateKey = window.getPrivateKey()
        this.provider = new ethers.providers.JsonRpcProvider({ url: 'https://hz.node.quarkblockchain.cn' })
        this.signer = new ethers.Wallet(privateKey, this.provider)
        await this.getAddress()
      }
    },
    async isQKI () {
      const network = await this.provider.getNetwork()
      const networkVersion = network.chainId
      if (networkVersion !== GLOBAL_CONFIGS.chainId) {
        toast('你当前没有使用' + GLOBAL_CONFIGS.network + '主网，请切换主网为' + GLOBAL_CONFIGS.network)
        return false
      }
      return true
    },
    // 获取地址
    async getAddress () {
      const [error, address] = await this.toAsync(this.signer.getAddress())
      if (error == null) {
        this.address = address
      } else {
        console.log(error)
      }
    },
    // 查询Transaction,完成后回调
    async queryTransation (hash, fnCallback) {
      await this.provider.waitForTransaction(hash).then(async receipt => {
        console.log(receipt)
        if (receipt.status === 0) {
          toast('合约调用失败', receipt)
        } else {
          toast('合约调用成功', receipt)
        }
        fnCallback && fnCallback()
      })
    },
    // 部署合约,此方法仅为记录，并不常用
    async deloyConstract (newAbi, bytecode) {
      const factory = new ethers.ContractFactory(newAbi, bytecode, this.signer)
      const [error, data] = await this.toAsync(factory
        .deploy(this.totalAmount, this.name, this.precision, this.shortName, {
          gasLimit: 2000000,
          gasPrice: ethers.utils.parseUnits('1000', 'gwei'),
        }))
      console.log(error, data)
      this.queryTransation(data.deployTransaction.hash)
    },
    // 十六进制转10进制
    hex2int (hex) {
      if (hex.indexOf('0x') >= 0) {
        hex = hex.substring('2')
      }
      const len = hex.length
      const a = new Array(len)
      let code
      for (let i = 0; i < len; i++) {
        code = hex.charCodeAt(i)
        if (code >= 48 && code < 58) {
          code -= 48
        } else {
          code = (code & 0xdf) - 65 + 10
        }
        a[i] = code
      }
      return a.reduce(function (acc, c) {
        acc = 16 * acc + c
        return acc
      }, 0)
    },
    // 统一封装异步请求
    toAsync (fnPromise) {
      return fnPromise.then(res => [null, res]).catch(error => [error])
    },
    // response公共处理方法
    doResponse (error, res, keyName, Decimal = 18) {
      if (error === null) {
        if (keyName) {
          const hex = ethers.utils.hexValue(res)
          // const Value =
          //   this.hex2int(hex) / ethers.BigNumber.from(10).pow(Decimal)
          const Value = Big(this.hex2int(hex)) / (ethers.BigNumber.from(10).pow(Decimal))
          this[keyName] = Big(Value).toFixed()
          // this[keyName] = Value
          // 根据实际项目做调整
          // const etherString = ethers.utils.formatEther(res)
          // this[keyName] = parseFloat(etherString)
        }
        return true
      } else {
        // 根据实际项目做调整
        if (error.code === 'INSUFFICIENT_FUNDS') {
          toast('矿工费不足')
        } else if (error.code === 4001) {
          toast('用户取消')
        } else {
          toast('错误代码:' + error.data.message)
        }
        return false
      }
    },
  },
}

// 时间处理工具
export const timeUtils = {
  data () {
    return {
      day: '0',
      hour: '00',
      minutes: '00',
      seconds: '00',
    }
  },
  methods: {
    // 获取当前时间
    getCurrTime () {
      const timeStr = (Date.now()).toString().substring(0, 10)
      return timeStr
    },
    // 时间戳转时间
    timestampToTime (timestamp) {
      var date = new Date(timestamp * 1000) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D = date.getDate() + ' '
      var h = date.getHours() + ':'
      var m = date.getMinutes() + ':'
      var s = date.getSeconds()
      return Y + M + D + h + m + s
    },
    // 倒计时
    countDown (maxtime, fnCallback) {
      let distance = maxtime
      if (maxtime >= 0) {
        // 距离结束剩下多少天
        const day = Math.floor(maxtime / 86400)
        // 得到剩下的分钟数
        maxtime -= day * 86400
        let hour = Math.floor(maxtime / 3600)
        // 得到剩下的分钟数
        maxtime -= hour * 3600
        let minutes = Math.floor(maxtime / 60)
        let seconds = Math.floor(maxtime % 60)
        --distance
        this.day = day.toString()
        if (hour < 10) {
          hour = '0' + hour
        }
        this.hour = hour.toString()
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        this.minutes = minutes.toString()
        if (seconds < 10) {
          seconds = '0' + seconds
        }
        this.seconds = seconds.toString()
        this.timer = setTimeout(() => {
          this.countDown(distance, fnCallback)
        }, 1000)
      } else {
        clearInterval(this.timer)
        fnCallback && fnCallback()
      }
    },
  },
}

// 比较两个版本
export const compareVersion = {
  methods: {
    // ios比较版本
    compareIosVersion (nowVersion, requestVersion) {
      const reg = /(\d+)\.(\d+)\.(\d+)/
      let nowArr = []
      let requestArr = []
      // 参数检查
      try {
        if (nowVersion.match(reg).length !== 4 || requestVersion.match(reg).length !== 4) {
          console.log('Error compareVersion', 'param')
          return
        }
      } catch (e) {
        console.log('Error compareVersion', 'param')
        return
      }

      nowArr = nowVersion.match(reg).slice(1, 4)
      requestArr = requestVersion.match(reg).slice(1, 4)
      for (let i = 0; i < 3; i++) {
        console.log(nowArr[i], requestArr[i])
        if (nowArr[i] < requestArr[i]) {
          return false
        }
        if (nowArr[i] > requestArr[i]) {
          return true
        }
      }
      return true
    },
  },
}
