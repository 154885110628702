<template>
  <div class="nav-bar padding-lr padding-tb-lg">
    <div class="title text-center">
      <slot>{{title}}</slot>
    </div>
    <div class="content padding-lr flex align-center justify-between">
      <a class="flex align-center" @click="$router.back()">
        <icon type="left"></icon>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nav-bar',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-bar {
  position: relative;
  .title {
    min-height: 25px;
    font-size: 16px;
  }
  .content {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
  .icon-left {
    width: 17px;
    height: 17px;
  }
}
</style>
