import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { APP_LANG } from '@/commons/const'
import zh from './zh'
import en from './en'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorage.getItem(APP_LANG) || navigator.language,
  silentFallbackWarn: true,
  messages: {
    en,
    zh,
  },
})

export default i18n
