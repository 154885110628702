<template>
  <div class="add-liq">
    <div class="card">
      <nav-bar>{{$t('createLiq')}}</nav-bar>
      <div class="item">
        <div class="label">{{from.contract_origin}}</div>
        <div class="flex justify-between align-center padding-lr-sm">
          <input
            class="num margin-lr-xs"
            v-model="fromInput"
            placeholder="0.0"
            @input="onFromInput"
          />
        </div>
      </div>
      <div
        class="text-center text-sm upper-case"
        v-show="fromRemainAmount !== null"
      >可用：{{fromRemainAmount}} {{from.name}}</div>
      <div class="text-center">
        <icon type="add"></icon>
      </div>
      <div class="item">
        <div class="label">{{to.contract_origin}}</div>
        <div class="flex justify-between align-center padding-lr-sm">
          <input class="num margin-lr-xs" v-model="toInput" placeholder="0.0" @input="onToInput" />
        </div>
      </div>
      <div
        class="text-center text-sm upper-case"
        v-show="toRemainAmount !== null"
      >可用：{{toRemainAmount}} {{to.name}}</div>
    </div>
    <!-- <div v-show="showQa" class="padding-lr margin-tb-sm flex justify-between text-md">
      <span class="text-gray upper-case">QA卖出数量</span>
      <span>{{qaSaleAmount}}</span>
    </div> -->
    <div class="padding flex justify-between align-center text-md">
      <span class="text-gray">{{$t('tolerance')}}</span>
      <a @click="$refs.toleranceDialog.open()">
        {{toleranceValue|mul(100)}}%
        <icon class="text-gray margin-left-xs" type="edit" />
      </a>
    </div>
    <div v-show="predictPer" class="padding flex justify-between text-md">
      <span class="text-gray upper-case">{{$t('predictPer')}}</span>
      <span>{{predictPer}}%</span>
    </div>
    <div class="footer" v-if="authorizeFlagA && authorizeFlagB">
      <button class="dui-button block bg-blue" @click="onSubmit">{{$t('confirmAdd')}}</button>
    </div>
    <div class="footer flex_h_between" v-else>
      <button
        :class="['dui-button', 'flex1', 'mr_15', authorizeFlagA ? 'grey-blue':'bg-blue']"
        @click="doAuthorize(from, 'from')"
      >
        <img :src="require('../../assets/images/loading.gif')" alt v-if="loadingA" class="loading" />
        {{$t('authorize')}} {{(from.name || '').toUpperCase()}}
      </button>
      <button
        :class="['dui-button' ,'flex1', authorizeFlagB ? 'grey-blue':'bg-blue']"
        @click="doAuthorize(to, 'to')"
      >
        <img :src="require('../../assets/images/loading.gif')" alt v-if="loadingB" class="loading" />
        {{$t('authorize')}} {{(to.name || '').toUpperCase()}}
      </button>
    </div>
    <dui-dialog ref="toleranceDialog">
      <ToleranceDialog @close="$refs.toleranceDialog.close()" @confirm="onConfirm" />
    </dui-dialog>
  </div>
</template>

<script>
import { Decimal } from '@/commons/utils'
// import { ethers } from 'ethers'
import liquidity from '../commons/mixins/liquidity'

export default {
  mixins: [liquidity],
  methods: {
    // 获取交易对池总量， 计算比例
    // async getRate () {
    //   if (this.from?.name && this.to?.name) {
    //     let decimal0 = 2
    //     let decimal1 = 2
    //     const [err0, token0] = await this.toAsync(this.pairContract.token0())
    //     if (err0 == null) {
    //       // 对token0，和token1分别进行精度赋值
    //       const fromContract = this.from && (this.from.contract_origin).toUpperCase() === 'QKI' ? this.wqkiAddress.toUpperCase() : this.from && (this.from.contract_origin).toUpperCase()
    //       if (fromContract === token0.toUpperCase()) {
    //         decimal0 = this.fromDecimals
    //         decimal1 = this.toDecimals
    //       } else {
    //         decimal0 = this.toDecimals
    //         decimal1 = this.fromDecimals
    //       }
    //     }
    //     // 获取区块链上这个交易对的数量
    //     const [err2, res] = await this.toAsync(this.pairContract.getReserves())
    //     // 计算返回的值
    //     if (err2 == null) {
    //       const hex0 = ethers.utils.hexValue(res[0])
    //       const Value0 =
    //         this.hex2int(hex0) / ethers.BigNumber.from(10).pow(decimal0)
    //       const hex1 = ethers.utils.hexValue(res[1])
    //       const Value1 =
    //         this.hex2int(hex1) / ethers.BigNumber.from(10).pow(decimal1)
    //         // 对token0，和token1分别进行精度赋值
    //       const fromContract = this.from && (this.from.contract_origin).toUpperCase() === 'QKI' ? this.wqkiAddress.toUpperCase() : this.from && (this.from.contract_origin).toUpperCase()
    //       // 使用from类型的币种来计算预计占比，所以获取from类型的币种的总量
    //       if (fromContract === token0.toUpperCase()) {
    //         this.baseAsset = Value0
    //         this.fromAmount = Value0
    //         this.toAmount = Value1
    //         // 计算交易对比例
    //         this.rate = Decimal.div(Value1, this.baseAsset)
    //       } else {
    //         this.baseAsset = Value1
    //         this.toAmount = Value0
    //         this.fromAmount = Value1
    //         // 计算交易对比例
    //         this.rate = Decimal.div(Value0, this.baseAsset)
    //       }
    //       console.log('===========', this.fromAmount, this.toAmount)
    //     }
    //     if (this.lastOne === 'from') {
    //       this.fromCalcTo()
    //     } else if (this.toInput) {
    //       this.toCalcFrom()
    //     }
    //   }
    // },
    async getRate () {
      const { fromAmount, toAmount } = await this.getLiquidityAmount(
        this.from,
        this.to,
        this.fromDecimals,
        this.toDecimals,
      )
      this.fromAmount = fromAmount
      this.toAmount = toAmount
      this.baseAsset = fromAmount
      this.rate = Decimal.div(toAmount, fromAmount)
      if (this.lastOne === 'from') {
        this.fromCalcTo()
      } else if (this.toInput) {
        this.toCalcFrom()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  margin: 16px 10px;
  padding-bottom: 15px;
  background-color: var(--card-bg2);
  border-radius: 10px;
}
.item {
  margin: 6px 10px;
  padding: 16px 4px;
  background-color: var(--card-bg);
  border-radius: 10px;
}
.label {
  margin-left: 18px;
  color: var(--num-color);
  font-size: 12px;
}
.num {
  width: 100px;
  flex: 0px 1 1;
  font-size: 35px;
}
.icon-add {
  width: 10px;
  height: 12px;
}
.footer {
  margin: 100px 10px 10px;
  .dui-button {
    height: 55px;
    font-size: 16px;
    border-radius: 10px;
  }
}
body[data-dui-theme="dark"] {
  .item {
    --card-bg: #2b2d61;
  }
}
.item1 {
  display: block;
  margin: 12px 10px;
  padding-top: 30px;
  padding-bottom: 18px;
  // height: 80px;
  background: var(--card-bg2);
  box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 0.0299388);
  border-radius: 10px;
  font-size: 16px;
  .label {
    position: absolute;
    right: 0;
    bottom: 90%;
    font-size: 12px;
    color: #979ab6;
    white-space: nowrap;
  }
  .contract {
    font-size: 12px;
    color: #979ab6;
  }
}
</style>
