<template>
  <div class="coin-dialog">
    <div v-if="pageIndex === 0" :key="0">
      <div class="padding-lr-lg padding-tb flex align-center justify-between solid-bottom">
        <span>{{$t('selectCoin')}}</span>
        <a @click="onClose">
          <icon type="close"></icon>
        </a>
      </div>
      <div class="flex justify-between align-center padding-lr-sm">
        <input class="num margin-lr-xs input" v-model="inputAddress" placeholder="没找到？输入合约试一试" @input="onInputAddress"/>
        <button :class="['dui-button', 'use-btn']" @click="onUse">使用</button>
      </div>
      <div class="list">
        <div class="item padding-lr-xs" :class="{disabled: disable.some(item => item == coin.id)}" v-for="(coin, index) in list" :key="coin.id" @click="onClick(coin, index)">
          <div class="flex align-center">
            <div v-if="coin.icon" class="coin-icon bg-img margin-right round" v-src="coin.icon"></div>
            <div class="upper-case margin-right">{{coin.name}}</div>
            <div class="upper-case network round" :class="coin.network.name">{{coin.network.name}}</div>
          </div>
          <div class="contract">{{coin.contract}}</div>
        </div>
      </div>
      <!-- <div class="padding flex align-center solid-top" v-if="currentNetwork" @click="pageIndex = 1">
        <div class="coin-icon bg-img margin-right round" v-src="currentNetwork.icon"></div>
        <span class="flex-sub upper-case">{{currentNetwork.name}}</span>
        <span class="padding-left-lg padding-right-sm solid-left">更改</span>
      </div> -->
    </div>
    <div v-if="pageIndex === 1" :key="1">
      <div class="padding-lr-lg padding-tb flex align-center justify-between solid-bottom">
        <div @click="pageIndex = 0">
          <icon type="left"></icon>
        </div>
        <span>管理</span>
        <div @click="onClose">
          <icon type="close"></icon>
        </div>
      </div>
      <div class="list">
        <div class="item padding-lr-xs flex align-center" v-for="(network, index) in networkList" :key="network.id" @click="onSelectNetwork(network, index)">
          <div v-if="network.icon" class="coin-icon bg-img margin-right round" v-src="network.icon"></div>
          <div class="upper-case">
            <div>{{network.name}}</div>
            <div class="contract">{{network.title}}</div>
          </div>
          <div class="select-icon" :class="{ selected: networkIndex === index }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import base from './mixins/base'
import { GLOBAL_CONFIGS } from '../../commons/const'
export default {
  props: {
    disable: {
      type: Array,
      default: () => [],
    },
    options: Object,
  },
  data () {
    return {
      pageIndex: 0,
      networkIndex: 0,
      inputAddress: '',
    }
  },
  mixins: [base],
  computed: {
    ...mapState(['network']),
    networkList () {
      return this.options?.networks || this.network?.networks
    },
    currentNetwork () {
      return this.networkList?.[this.networkIndex]
    },
    list () {
      // const networkId = this.currentNetwork?.id
      return (this.options?.symbols || this.network?.symbols)?.filter(({ network }) => network.name === GLOBAL_CONFIGS.network)
      // return this.options?.symbols || this.network?.symbols
    },
  },
  methods: {
    onSelectNetwork (_, index) {
      this.networkIndex = index
      this.pageIndex = 0
    },
    onClick (coin, index) {
      // eslint-disable-next-line eqeqeq
      if (this.disable.some(item => item == coin.id)) {
        return
      }
      this.$emit('select', coin, index)
    },
    onInputAddress (e) {
      if (e.target.value !== '' && e.target.value.length !== 42) {
        this.$toast('请正确输入token地址')
      }
    },
    async onUse () {
      const name = await this.getTokenSymbol(this.inputAddress)
      const coin = {
        contract_origin: this.inputAddress,
        name,
        network: {
          name: GLOBAL_CONFIGS.network,
        },
      }

      this.$emit('select', coin)
    },
    onClose () {
      this.$emit('close')
      setTimeout(() => {
        this.pageIndex = 0
      }, 400)
    },
  },
}
</script>

<style lang="scss" scoped>
.coin-dialog {
  padding-top: 6px;
  width: 317px;
  background-color: var(--dialog-bg);
  border-radius: 10px;
  .list {
    padding-bottom: 20px;
    max-height: 400px;
    overflow: auto;
  }
  .item {
    padding: 18px 18px;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      background-color: var(--hover-bg);
    }
    &.disabled {
      cursor: not-allowed;
      background-color: var(--hover-bg);
      opacity: 0.38;
    }
    .network {
      padding: 0 14px;
      font-size: 10px;
      height: 22px;
      line-height: 22px;
      color: #fff;
      background-color: #3F3F3F;
      &.qki {
        background-color: #627EEA;
      }
    }
    .contract {
      font-size: 12px;
      opacity: 0.38;
    }
  }
  .add {
    margin-left: 10px;
    width: 56px;
    color: #000;
    background-color: #d8d8d8;
    border-radius: 5px;
    .icon-add {
      width: 18px;
      height: 18px;
    }
  }
  .coin-icon {
    width: 23px;
    height: 23px;
    background-color: transparent;
  }
  .select-icon {
    margin-left: auto;
    width: 28px;
    height: 28px;
    border: 5px solid #D8D8D8;
    border-radius: 50%;
    &.selected {
      border-width: 0;
      background-color: #628FF3;
    }
  }
}
.solid-bottom {
  border-color: #363636;
}
.solid-top,
.solid-left {
  border-color: #d8d8d8;
}
.text-blue {
  color: #3A70DD;
}
.use-btn{
  background: #3A70DD;
  font-size: 16px;
  color: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 6px 20px;
}
.input{
  // background: ;
  border-bottom: .5px solid #dddddd;
  display: flex;
  flex: 1;
  padding: 6px;
}
</style>
