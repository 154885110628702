<template>
  <div class="swap margin-top-lg">
    <div v-if="caseList.length > 0" class="padd">
      <div class="text-sm tx-color">当前交易对暂时没有流动性，您可以试试以下方案</div>
      <div class="flex_h_between mt_10" style="flex-wrap:wrap">
        <div
          v-for="(item, index) in caseList"
          :key="index"
          @click="selectCase(index)"
          class="dui-button deep_blue_btn upper-case flex_h_between"
          style="margin-bottom: 10px"
        ><div class="flex1">{{(from.name || '')}}/{{(item.name || '')}} => {{(item.name || '')}}/{{(to.name || '')}}</div><span>></span></div>
        <div class="dui-button yellow_btn flex_h_between" @click="goto" style="margin-bottom: 10px"><div class="flex1">去新增流动性</div><span>></span></div>
      </div>
    </div>
    <div class="item flex">
      <div class="label bg-red flex flex-direction align-center justify-center">
        <icon type="dot"></icon>
        <span class="margin-top-xxs">{{$t('fromLabel')}}</span>
      </div>
      <div class="flex-sub flex justify-between align-center padding-lr-sm">
        <input
          class="num margin-lr-xs"
          v-model="fromInput"
          type="text"
          placeholder="0.0"
          @input="onFromInput"
        />
        <CoinSelectButton :disable="[to.id]" :coin="from" @select="onFromSelect" />
      </div>
    </div>
    <div
      class="remain round text-center text-sm upper-case"
      v-show="fromRemainAmount !== null"
    >可用：{{fromRemainAmount}} {{from.name}}</div>
    <div class="item" style="height: auto;padding-bottom: 10px" v-show="fromAmount != 0 && toAmount != 0">
        <div class="text-center text-sm">{{$t('rate')}}</div>
        <div class="text-center text-sm" style="text-decoration: underline">{{value}}%</div>
        <div style="padding-left: 20px;padding-right: 20px"><van-slider v-model="value" @change="onChange" style="margin-top: 20px"/></div>

        <div class="flex_h_around" style="margin-top: 20px">
          <div class="flex1 rate_btn" @click="onChange(25)">25%</div>
          <div class="flex1 rate_btn" @click="onChange(50)">50%</div>
          <div class="flex1 rate_btn" @click="onChange(75)">75%</div>
          <div class="flex1 rate_btn" @click="onChange(100)">100%</div>
        </div>
      </div>
    <div class="margin-sm text-center" v-if="middleware == null">
      <a @click="onExchange">
        <icon type="arrow-down"></icon>
      </a>
    </div>
    <div class="item flex" v-if="middleware != null">
      <div class="label bg-green flex flex-direction align-center justify-center">
        <icon type="dot"></icon>
        <span class="margin-top-xxs">{{$t('MidLab')}}</span>
      </div>
      <div class="flex-sub flex justify-between align-center padding-lr-sm">
        <input
          class="num margin-lr-xs"
          v-model="middlewareInput"
          type="text"
          placeholder="0.0"
          @input="onMiddlewareInput"
        />
      </div>
    </div>
    <div
      class="remain round text-center text-sm upper-case"
      v-if="middleware != null && middlewareRemainAmount !== null"
    >可用：{{middlewareRemainAmount}} {{middleware.name}}</div>
    <div class="item flex">
      <div class="label bg-blue flex flex-direction align-center justify-center">
        <icon type="circle"></icon>
        <span class="margin-top-xxs">{{$t('toLabel')}}</span>
      </div>
      <div class="flex-sub flex justify-between align-center padding-lr-sm">
        <input
          class="num margin-lr-xs"
          v-model="toInput"
          type="text"
          placeholder="0.0"
          @input="onToInput"
        />
        <CoinSelectButton :disable="[from.id]" :coin="to" @select="onToSelect" />
      </div>
    </div>
    <div
      class="remain round text-center text-sm upper-case"
      v-show="toRemainAmount !== null"
    >可用：{{toRemainAmount}} {{to.name}}</div>
    <div
      v-show="averagePrice"
      class="padding-lr margin-top-lg flex justify-between align-center text-md"
    >
      <span class="text-gray">{{$t('averagePrice')}}</span>
      <span class="upper-case">1{{from.name}}={{averagePrice}}{{to.name}}</span>
    </div>
    <div v-show="fromAmount" class="padding-lr margin-tb-sm flex justify-between text-md">
      <span class="text-gray upper-case">{{from.name}}</span>
      <span>{{fromAmount}}</span>
    </div>
    <div v-show="middlewareAmountA" class="padding-lr margin-tb-sm flex justify-between text-md">
      <span class="text-gray upper-case">{{middleware && middleware.name}}</span>
      <span>{{middlewareAmountA}}</span>
    </div>
    <div v-show="middlewareAmountB" class="padding-lr margin-tb-sm flex justify-between text-md">
      <span class="text-gray upper-case">{{middleware && middleware.name}}</span>
      <span>{{middlewareAmountB}}</span>
    </div>
    <div v-show="toAmount" class="padding-lr margin-tb-sm flex justify-between text-md">
      <span class="text-gray upper-case">{{to.name}}</span>
      <span>{{toAmount}}</span>
    </div>
    <!-- <div v-show="showQa" class="padding-lr margin-tb-sm flex justify-between text-md">
      <span class="text-gray upper-case">QA卖出数量</span>
      <span>{{qaSaleAmount}}</span>
    </div> -->
    <div class="padding-lr margin-tb-sm flex justify-between align-center text-md">
      <span class="text-gray">{{$t('tolerance')}}</span>
      <a @click="$refs.toleranceDialog.open()">
        {{toleranceValue|mul(100)}}%
        <icon class="text-gray margin-left-xs" type="edit" />
      </a>
    </div>
    <div class="footer" v-if="authorizeFlagA">
      <button class="dui-button block bg-blue" @click="onSubmit">{{$t('confirm')}}</button>
    </div>
    <div class="footer" v-else>
      <button class="dui-button block bg-blue" @click="doAuthorize">
        <img :src="require('../../assets/images/loading.gif')" alt v-if="loadingA" class="loading" />
        {{($t('authorize') + (from.name || '').toUpperCase())}}
      </button>
    </div>
    <a class="card yellow-blue flex justify-center align-center" v-if="from.name && to.name" @click="copy">
      {{$t('shareSwap')}}
    </a>
    <dui-dialog ref="toleranceDialog">
      <ToleranceDialog @close="$refs.toleranceDialog.close()" @confirm="onConfirm" />
    </dui-dialog>
    <dui-dialog ref="confirmDialog">
      <ConfirmDialog
        content="确定操作吗？"
        @confirm="onConfirmSubmit"
        @cancel="handleClose"
        :confirmText="$t('confirm')"
      />
    </dui-dialog>
    <dui-dialog ref="addLiquidityDialog">
      <ConfirmDialog
        content="当前交易对没有流动性，去添加流动性？"
        @confirm="goto"
        @cancel="$refs.addLiquidityDialog.close()"
        :confirmText="$t('goto')"
      />
    </dui-dialog>
    <dui-dialog ref="loadingDialog">
      <div class="loading_wrap">
        <div class="flex_v_center_center" style="width: 100%"><img :src="require('../../assets/images/loading.gif')" alt class="loading" /></div>
        <div style="color: #333;font-size: 12px;margin-top: 10px">提交请求成功，等待区块确认</div>
      </div>
    </dui-dialog>
  </div>
</template>

<script>
import { Decimal, h5Copy } from '@/commons/utils'
import { mapState } from 'vuex'
import CoinSelectButton from '../commons/CoinSelectButton'
import ToleranceDialog from '../commons/ToleranceDialog'
import ConfirmDialog from '../commons/ConfirmDialog'
import { ethers } from 'ethers'
import base from '../commons/mixins/base'
import { PAIR_ABI, GLOBAL_CONFIGS } from '@/commons/const'

export default {
  components: {
    CoinSelectButton,
    ToleranceDialog,
    ConfirmDialog,
  },
  data () {
    return {
      fromInput: '',
      toInput: '',
      selectType: null,
      lastOne: '', // from to
      submiting: false,
      averagePrice: null,

      fromRemainAmount: null,
      toRemainAmount: null,

      from: {},
      to: {},
      fee: 0.003,
      loadingA: false,
      authorizeFlagA: false,
      rate: null,

      baseAsset: null, // 基础资产
      fromAmount: 0, // 资金池剩余数量
      toAmount: 0,
      fromContract: null, // from币种的合约
      toContract: null, // to币种的合约
      fromDecimals: 2, // from的精度
      toDecimals: 2, // to的精度
      usdtAddress: GLOBAL_CONFIGS.u_address,
      caseList: [], // 方案列表
      middleware: null, // 方案中间件
      middlewareInput: '',
      middlewareContract: null, // 中间件合约
      middlewareAmountA: 0, // 中间件剩余数量
      middlewareAmountB: 0, // B交易对剩余数量
      middlewareDecimals: 2, // 中间件精度
      middlewareRemainAmount: null,
      rateA: null,
      rateB: null,
      pairAddressA: '', // 多交易对时缓存交易对一的交易对地址
      pairContractA: null, // 多交易对时缓存交易对一的交易对合约

      value: 0,
    }
  },
  mixins: [base, h5Copy],
  computed: {
    ...mapState(['network']),
    // ...mapGetters(['fee']),
  },
  watch: {
    network (value) {
      if (value) {
        const { tokenAName } = this.$route.query
        if (!tokenAName) {
          this.init(value)
        }
      }
    },
    async from (newCoin) {
      this.fromContract =
        newCoin.contract_origin === '' ? null : this.getContract(newCoin)
      this.fromDecimals =
        newCoin.contract_origin === ''
          ? 18
          : await this.getDecimal(this.fromContract)
      this.calcLiquidity(newCoin, this.to)
    },
    async to (newCoin) {
      this.toContract =
        newCoin.contract_origin === '' ? null : this.getContract(newCoin)
      this.toDecimals =
        newCoin.contract_origin === ''
          ? 18
          : await this.getDecimal(this.toContract)
      this.calcLiquidity(newCoin, this.from)
    },
  },
  created () {
    const { tokenA, tokenB, tokenBName, tokenAName } = this.$route.query
    if (tokenAName) {
      this.from = {
        contract_origin: tokenA,
        network: {
          name: GLOBAL_CONFIGS.network,
        },
        name: tokenAName,
      }
      this.to = {
        contract_origin: tokenB,
        network: {
          name: GLOBAL_CONFIGS.network,
        },
        name: tokenBName,
      }
      if (tokenA === '') {
        this.authorizeFlagA = true
      }
    }
  },
  methods: {
    init (value) {
      this.from = (value?.symbols)?.filter(({ network }) => network.name === GLOBAL_CONFIGS.network)[0]
      if (this.from.contract_origin === '') {
        this.authorizeFlagA = true
      } else {
        this.authorizeFlagA = false
      }
      this.getBalance(this.from, 'fromRemainAmount')
    },
    // 来源选择回调
    async onFromSelect (coin) {
      this.from = coin

      if (coin.contract_origin === '') {
        this.authorizeFlagA = true
      } else {
        this.authorizeFlagA = false
      }
      this.initMoreLiquidity()
      this.getBalance(this.from, 'fromRemainAmount')
    },
    // 去源选择回调
    onToSelect (coin) {
      this.to = coin
      this.authorizeFlagB = false
      this.initMoreLiquidity()
      this.getBalance(coin, 'toRemainAmount')
    },
    // 获取交易对池总量， 计算比例
    async getRate (params) {
      const tokenA = params?.tokenA || this.from
      const tokenB = params?.tokenB || this.to
      const tokenADecimals = params?.tokenADecimals || this.fromDecimals
      const tokenBDecimals = params?.tokenBDecimals || this.toDecimals
      const amountKeyNameA = params?.amountKeyNameA || 'fromAmount'
      const amountKeyNameB = params?.amountKeyNameB || 'toAmount'
      const inputA = params?.inputA || 'fromInput'
      const inputB = params?.inputB || 'toInput'
      const rateKey = params?.rateKey || 'rate'
      const pairContractName = params?.pairContractName || 'pairContract'
      const timerName = params?.timerName || 'timer'
      clearTimeout(this[timerName])
      if (tokenA.name && tokenB.name) {
        if (this.liquidityAble) {
          // 计算amount
          const { fromAmount, toAmount } = await this.getLiquidityAmount(
            tokenA,
            tokenB,
            tokenADecimals,
            tokenBDecimals,
            pairContractName,
          )
          this.baseAsset = fromAmount
          this[amountKeyNameA] = fromAmount
          this[amountKeyNameB] = toAmount
          this[rateKey] = Decimal.div(toAmount, fromAmount)
        } else {
          // 不需要进行替换，因为跨流动性不会走这个判断
          this.rate = null
          this.baseAsset = null
          this.fromAmount = 0
          this.toAmount = 0
          this.calcMoreLiquidity()
        }
        // 计算兑换
        if (this.lastOne === 'from') {
          this[inputA] &&
            (this[inputB] = this.fromCalcTo(
              this[inputA],
              this[amountKeyNameA],
              this[amountKeyNameB],
            ))
          if (inputB !== 'toInput') {
            this.toInput = this.fromCalcTo(
              this.middlewareInput,
              this.middlewareAmountA,
              this.toAmount,
              true,
            )
          }
        } else if (this.lastOne === 'to') {
          this[inputB] &&
            (this[inputA] = this.toCalcFrom(
              this[inputB],
              this[amountKeyNameA],
              this[amountKeyNameB],
            ))
          if (inputA !== 'fromInput') {
            this.fromInput = this.toCalcFrom(
              this.middlewareInput,
              this.fromAmount,
              this.middlewareAmountA,
            )
          }
        }

        // 计算均价
        if (this.fromInput && this.toInput) {
          this.averagePrice = Decimal.div(this.toInput, this.fromInput)
        }
        if (this[rateKey] != null) {
          this[timerName] = setTimeout(() => {
            const params1 = {
              tokenA,
              tokenB,
              tokenADecimals,
              tokenBDecimals,
              amountKeyNameA,
              amountKeyNameB,
              inputA,
              inputB,
              rateKey,
              pairContractName,
              timerName,
            }
            this.getRate(params1)
          }, 10000)
        }
      }
    },
    onFromInput (e) {
      const inputValue = e.target.value
      this.fromInput = this.$util.inputNumber(inputValue)
      if (!this.fromInput) {
        this.toInput = ''
        if (this.middlewareAmountA) {
          this.middlewareInput = ''
        }
      } else {
        if (this.middlewareAmountA) {
          this.middlewareInput = this.fromCalcTo(
            this.fromInput,
            this.fromAmount,
            this.middlewareAmountA,
          )
          this.toInput = this.fromCalcTo(
            this.middlewareInput,
            this.middlewareAmountB,
            this.toAmount,
            true,
          )
        } else {
          this.toInput = this.fromCalcTo(
            this.fromInput,
            this.fromAmount,
            this.toAmount,
          )
        }
      }
      this.lastOne = 'from'
      // 计算均价
      if (this.fromInput && this.toInput) {
        this.averagePrice = Decimal.div(this.toInput, this.fromInput)
      }
    },
    onToInput (e) {
      const inputValue = e.target.value
      this.toInput = this.$util.inputNumber(inputValue)
      if (!this.toInput) {
        if (this.middlewareAmountA) {
          this.fromInput = ''
          this.middlewareInput = ''
        } else {
          this.fromInput = ''
        }
      } else {
        if (this.middlewareAmountA) {
          this.middlewareInput = this.toCalcFrom(
            this.toInput,
            this.middlewareAmountB,
            this.toAmount,
          )
          this.fromInput = this.toCalcFrom(
            this.middlewareInput,
            this.fromAmount,
            this.middlewareAmountA,
          )
        } else {
          this.fromInput = this.toCalcFrom(
            this.toInput,
            this.fromAmount,
            this.toAmount,
          )
        }
      }
      // this.averagePrice = Decimal.div(this.toInput, this.fromInput)
      this.lastOne = 'to'
      // 计算均价
      if (this.fromInput && this.toInput) {
        this.averagePrice = Decimal.div(this.toInput, this.fromInput)
      }
    },
    // 中间件input
    onMiddlewareInput (e) {
      const inputValue = e.target.value
      this.middlewareInput = this.$util.inputNumber(inputValue)
      if (!this.middlewareInput) {
        this.toInput = ''
        this.fromInput = ''
      } else {
        this.fromInput = this.toCalcFrom(
          this.middlewareInput,
          this.fromAmount,
          this.middlewareAmountB,
        )
        this.toInput = this.fromCalcTo(
          this.middlewareInput,
          this.middlewareAmountA,
          this.toAmount,
        )
      }
    },
    fromCalcTo (value = this.fromInput, fromAmount, toAmount, noFee) {
      // this.getQaSaleAmount(this.fromContract, this.fromDecimals, this.fromInput)
      if (fromAmount && toAmount) {
        // this.toAmount - x = this.fromAmount * this.toAmount / (this.fromAmount + this.fromInput * (1 - this.fee))
        // 减去手续费
        let inputAmount = value
        if (!noFee) {
          let fee = this.fee
          if (
            (
              (this.fromContract && this.fromContract.contract) ||
              ''
            ).toLowerCase() === '0x3fb708e854041673433e708fedb9a1b43905b6f7'
          ) {
            fee = 0.2
          }
          inputAmount = Decimal.mul(value, Decimal.sub(1, fee))
        }
        // 资金池当前 from 的数量 = 已有from数量 + 用户输入的数量（已减去手续费）
        const currentFromAmount = Decimal.add(fromAmount, inputAmount)
        // 资金池 k (不变)
        const k = Decimal.mul(fromAmount, toAmount)
        // 资金池当前 to 的数量 =  k / 当前from数量 (k 不变，from增加，当前to必定减少)
        const currentToAmount = Decimal.div(k, currentFromAmount)
        // 可以兑换的数量 = 已有 to 的数量 - 资金池当前 to 的数量
        return Decimal.sub(toAmount, currentToAmount)
      }
      return ''
    },
    toCalcFrom (value = this.toInput, fromAmount, toAmount, noFee) {
      if (fromAmount && toAmount && Decimal.sub(toAmount, value) > 0) {
        // 同上
        // this.fromAmount + x * (1 - this.fee)  =  this.fromAmount * this.toAmount / (this.toAmount - this.toInput)
        // 当前资金池 to 数量 = 已有 to 数量 + 输入数量
        const currentToAmount = Decimal.sub(toAmount, value)
        // k
        const k = Decimal.mul(fromAmount, toAmount)
        // 当前资金池 from 数量
        const currentFromAmount = Decimal.div(k, currentToAmount)
        // 可兑换的 from 数量 + 手续费 = 资金池已有 from 数量 - 当前 from 数量
        const gainFromAmount = Decimal.sub(currentFromAmount, fromAmount)
        // 减去手续费
        if (!noFee) {
          let fee = this.fee
          if (
            (
              (this.toContract && this.toContract.contract) ||
              ''
            ).toLowerCase() === '0x3fb708e854041673433e708fedb9a1b43905b6f7'
          ) {
            fee = 0.2
          }
          return Decimal.div(gainFromAmount, Decimal.sub(1, fee))
        } else {
          return Decimal.div(gainFromAmount, 1)
        }
      } else {
        return ''
      }
    },
    onExchange () {
      [this.from, this.to] = [this.to, this.from];
      [this.fromRemainAmount, this.toRemainAmount] = [
        this.toRemainAmount,
        this.fromRemainAmount,
      ];
      [this.fromInput, this.toInput] = [this.toInput, this.fromInput]
      this.lastOne = this.lastOne === 'from' ? 'to' : 'from'
      this.getRate()
    },
    onSubmit () {
      if (this.submiting) {
        return
      }
      if (!this.from?.name) {
        return this.$toast(
          this.$t('placeholder.select', { name: this.$t('spentAssetType') }),
        )
      }
      if (!this.fromInput) {
        return this.$toast(
          this.$t('placeholder.input', { name: this.$t('spentAssetAmount') }),
        )
      }
      if (!this.to?.name) {
        return this.$toast(
          this.$t('placeholder.select', { name: this.$t('gainAssetType') }),
        )
      }
      if (!this.toInput) {
        return this.$toast(
          this.$t('placeholder.input', { name: this.$t('gainAssetAmount') }),
        )
      }
      this.$refs.confirmDialog.open()
    },
    // 初始化跨流动性方案
    initMoreLiquidity () {
      this.caseList = []
      this.middleware = null // 方案中间件
      this.middlewareInput = ''
      this.middlewareContract = null // 中间件合约
      this.middlewareAmountA = 0 // 中间件剩余数量
      this.middlewareAmountB = 0 // B交易对剩余数量
      this.middlewareDecimals = 2 // 中间件精度
      this.middlewareRemainAmount = null
      this.rateA = null
      this.rateB = null
      this.pairAddressA = '' // 多交易对时缓存交易对一的交易对地址
      this.pairContractA = null // 多交易对时缓存交易对一的交易对合约
    },
    // 计算跨流动性方案
    async calcMoreLiquidity () {
      // 排除a,b是qki的情况
      if (this.from.contract_origin === '' || this.to.contract_origin === '') {
        this.$refs.addLiquidityDialog.open()
        return
      }
      // 排除a,b是wqki的情况
      if (
        (this.from.contract_origin || '').toUpperCase() ===
          this.wqkiAddress.toUpperCase() ||
        (this.to.contract_origin || '').toUpperCase() ===
          this.wqkiAddress.toUpperCase()
      ) {
        this.$refs.addLiquidityDialog.open()
        return
      }
      // 排除a,b是qusdt的情况
      if (
        (this.from.contract_origin || '').toUpperCase() ===
          this.usdtAddress.toUpperCase() ||
        (this.to.contract_origin || '').toUpperCase() ===
          this.usdtAddress.toUpperCase()
      ) {
        this.$refs.addLiquidityDialog.open()
      }

      const addrList = [
        {
          contract_origin: '',
          name: GLOBAL_CONFIGS.symbol,
          network: {
            name: GLOBAL_CONFIGS.network,
          },
        },
        {
          contract_origin: this.usdtAddress,
          name: 'qust',
          network: {
            name: GLOBAL_CONFIGS.network,
          },
        },
      ]
      // const caseList = []
      // 方案一: a->qki qki->b
      for (let i = 0; i < addrList.length; i++) {
        let contract = addrList[i].contract_origin
        if (contract === '') {
          contract = this.wqkiAddress
        }
        let status = await this.simpleCalcLiquidity(
          this.from.contract_origin,
          contract,
        )
        if (status) {
          status = await this.simpleCalcLiquidity(
            contract,
            this.to.contract_origin,
          )
        }
        if (status) {
          this.caseList.push(addrList[i])
        }
      }
      if (this.caseList.length === 0) {
        this.$refs.addLiquidityDialog.open()
      }
    },
    // 简易检查流动性
    async simpleCalcLiquidity (fromContract, toContract) {
      const [error, res] = await this.toAsync(
        this.factoryContract.getPair(fromContract, toContract),
      )
      if (error == null) {
        if (res === ethers.constants.AddressZero) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    // 是否具有流动性，如果没有流动性，新增流动性，有则添加流动性
    async calcLiquidity (
      fromCoin,
      toCoin,
      status,
      pairAddressName = 'pairAddress',
      pairContractName = 'pairContract',
    ) {
      if (fromCoin.name && toCoin.name) {
        this.loading = true
        const fromContract = fromCoin.contract_origin || this.wqkiAddress
        const toContract = toCoin.contract_origin || this.wqkiAddress
        const [error, res] = await this.toAsync(
          this.factoryContract.getPair(fromContract, toContract),
        )
        this.loading = false
        if (error == null) {
          if (res === ethers.constants.AddressZero) {
            this.liquidityAble = false
          } else {
            this[pairAddressName] = res
            this[pairContractName] = new ethers.Contract(
              res,
              PAIR_ABI,
              this.signer,
            )
            this.liquidityAble = true
          }
        } else {
          this.liquidityAble = false
        }
      } else {
        this.liquidityAble = false
      }
      if (!status) {
        this.getRate()
      }
    },
    // 兑换
    async onConfirmSubmit () {
      this.submiting = true
      // 计算数量
      const fromDecimals = this.fromDecimals
      const toDecimals = this.toDecimals
      // const fromAmount = Decimal.mul(
      //   this.fromInput,
      //   ethers.BigNumber.from(10).pow(fromDecimals),
      // )
      const fromAmount = await this.getQaSaleAmount(this.fromContract, fromDecimals, this.fromInput)
      // 这个值可以减去方差
      const toAmount = Decimal.mul(
        this.toInput,
        ethers.BigNumber.from(10).pow(toDecimals),
      )
      // 获取当前时间戳
      let path = [this.from.contract_origin, this.to.contract_origin]
      const timeStr = Date.now()
        .toString()
        .substring(0, 10)
      const amountIn = String(fromAmount).split('.')[0]
      const deadline = parseInt(timeStr) + 600
      const subToleranceValue = String(
        Decimal.mul(toAmount, Decimal.sub(1, this.toleranceValue)),
      ).split('.')[0]
      const amountOutMin = await this.getQaToSaleAmount(this.toContract, subToleranceValue)
      let res
      if (this.from.contract_origin === '') {
        path = [this.wqkiAddress, this.to.contract_origin]
        const gasLimit = await this.getEstimateGas(() =>
          this.routerConstract.estimateGas.swapExactQKIForTokens(
            amountOutMin,
            path,
            this.address,
            deadline,
            {
              value: amountIn,
            },
          ),
        )
        if (gasLimit === 0) {
          return
        }
        res = await this.toAsync(
          this.routerConstract.swapExactQKIForTokens(
            amountOutMin,
            path,
            this.address,
            deadline,
            {
              value: amountIn,
              gasLimit,
              gasPrice: ethers.utils.parseUnits(String(this.gasPrice), 'gwei'),
            },
          ),
        )
      } else if (this.to.contract_origin === '') {
        path = [this.from.contract_origin, this.wqkiAddress]
        const gasLimit = await this.getEstimateGas(() =>
          this.routerConstract.estimateGas.swapExactTokensForQKI(
            amountIn,
            amountOutMin,
            path,
            this.address,
            deadline,
          ),
        )
        if (gasLimit === 0) {
          return
        }
        res = await this.toAsync(
          this.routerConstract.swapExactTokensForQKI(
            amountIn,
            amountOutMin,
            path,
            this.address,
            deadline,
            {
              gasLimit,
              gasPrice: ethers.utils.parseUnits(String(this.gasPrice), 'gwei'),
            },
          ),
        )
      } else {
        if (this.middlewareInput) {
          // 跨流动性
          const contract =
            this.middleware.contract_origin === ''
              ? this.wqkiAddress
              : this.middleware.contract_origin
          path = [this.from.contract_origin, contract, this.to.contract_origin]
          const gasLimit = await this.getEstimateGas(() =>
            this.routerConstract.estimateGas.swapExactTokensForTokens(
              amountIn,
              amountOutMin,
              path,
              this.address,
              deadline,
            ),
          )
          if (gasLimit === 0) {
            return
          }
          res = await this.toAsync(
            this.routerConstract.swapExactTokensForTokens(
              amountIn,
              amountOutMin,
              path,
              this.address,
              deadline,
              {
                gasLimit,
                gasPrice: ethers.utils.parseUnits(String(this.gasPrice), 'gwei'),
              },
            ),
          )
        } else {
          const gasLimit = await this.getEstimateGas(() =>
            this.routerConstract.estimateGas.swapExactTokensForTokens(
              amountIn,
              amountOutMin,
              path,
              this.address,
              deadline,
            ),
          )
          if (gasLimit === 0) {
            return
          }
          res = await this.toAsync(
            this.routerConstract.swapExactTokensForTokens(
              amountIn,
              amountOutMin,
              path,
              this.address,
              deadline,
              {
                gasLimit,
                gasPrice: ethers.utils.parseUnits(String(this.gasPrice), 'gwei'),
              },
            ),
          )
        }
      }
      const [error, res1] = res
      if (this.doResponse(error, res1)) {
        this.$toast('提交请求成功，等待区块确认')
        this.$refs.confirmDialog.close()
        this.$refs.loadingDialog.open()
        this.queryTransation(res1.hash, () => {
          this.submiting = false
          this.fromInput = ''
          this.toInput = ''
          this.$refs.loadingDialog.close()
          this.initMoreLiquidity()
          this.calcLiquidity(this.from, this.to)
          this.getBalance(this.from, 'fromRemainAmount')
          this.getBalance(this.to, 'toRemainAmount')
        })
      } else {
        this.submiting = false
      }
    },
    // 调用token的授权方法对路由地址进行授权
    async doAuthorize () {
      if (this.loadingA) {
        return
      }
      if (this.authorizeFlagA) {
        return
      }
      if (!this.from.name) {
        this.$toast('请选择需要授权的币种')
        return
      }
      const amount = this.fromInput
      const contract = this.fromContract
      const decimals = this.fromDecimals
      if (amount === '') {
        this.$toast('请输入需要授权的币种数量')
        return
      }
      this.loadingA = true
      const status = await this.checkAuthorize(
        contract,
        this.routerAddress,
        decimals,
        amount,
      )
      if (status) {
        this.transationCallback()
      } else {
        await this.authorize(
          amount,
          decimals,
          contract,
          this.routerAddress,
          this.transationCallback,
        )
      }
    },
    // 区块链确认回调
    transationCallback () {
      this.authorizeFlagA = true
      this.loadingA = false
    },
    // 前往流动池页面
    goto () {
      this.$router.push({
        name: 'ModulePool',
        query: {
          tokenA: this.from.contract_origin,
          tokenB: this.to.contract_origin,
          tokenAName: this.from.name,
          tokenBName: this.to.name,
        },
      })
    },
    // 选择方案
    async selectCase (index) {
      const coin = this.caseList[index]
      this.middleware = coin
      this.middlewareContract =
        coin.contract_origin === '' ? null : this.getContract(coin)
      this.middlewareDecimals =
        coin.contract_origin === ''
          ? 18
          : await this.getDecimal(this.fromContract)
      this.getBalance(coin, 'middlewareRemainAmount')
      await this.calcLiquidity(
        this.from,
        coin,
        true,
        'pairAddressA',
        'pairContractA',
      )
      const fromParams = {
        tokenA: this.from,
        tokenB: coin,
        tokenADecimals: this.fromDecimals,
        tokenBDecimals: this.middlewareDecimals,
        amountKeyNameA: 'fromAmount',
        amountKeyNameB: 'middlewareAmountA',
        inputA: 'fromInput',
        inputB: 'middlewareInput',
        rateKey: 'rateA',
        pairContractName: 'pairContractA',
        timerName: 'timerA',
      }
      await this.getRate(fromParams)
      await this.calcLiquidity(coin, this.to, true)
      const toParams = {
        tokenA: coin,
        tokenB: this.to,
        tokenADecimals: this.middlewareDecimals,
        tokenBDecimals: this.toDecimals,
        amountKeyNameA: 'middlewareAmountB',
        amountKeyNameB: 'toAmount',
        inputA: 'middlewareInput',
        inputB: 'toInput',
        rateKey: 'rateB',
      }
      await this.getRate(toParams)
    },
    copy () {
      if (this.from?.name && this.to?.name) {
        const origin = (window.location.href || '').split('#')[0]
        const query = {
          tokenA: this.from.contract_origin,
          tokenB: this.to.contract_origin,
          tokenAName: this.from.name,
          tokenBName: this.to.name,
          // pairAddress: this.pairAddress || '',
        }
        const queryArr = []
        for (const key in query) {
          queryArr.push(key + '=' + query[key])
        }
        const url = origin + '#/' + 'swap' + '?' + queryArr.join('&')
        this.h5Copy(url)
      } else {
        this.$toast('请选择需要操作的交易对！')
      }
    },
    handleClose () {
      this.$refs.confirmDialog.close()
      this.submiting = false
    },
    onChange (value) {
      this.value = value
      const rate = Decimal.div(value, 100)
      this.fromInput = Decimal.mul(this.fromRemainAmount, rate)
      this.toInput = this.fromCalcTo(
        this.fromInput,
        this.fromAmount,
        this.toAmount,
      )
    },
  },
  destroyed () {
    clearTimeout(this.timer)
    clearTimeout(this.timerA)
  },
}
</script>

<style lang="scss" scoped>
.card {
  margin: 12px 10px;
  height: 80px;
  box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 0.0299388);
  border-radius: 10px;
  font-size: 17px;
}
.icon-arrow-down {
  width: 10px;
  height: 12px;
}
.icon-edit {
  width: 14px;
  height: 14px;
}
.padd {
  margin: 8px 10px 15px;
}
.item {
  margin: 8px 10px;
  height: 80px;
  border-radius: 10px;
  background-color: var(--card-bg2);
  box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 0.0299388);
  overflow: hidden;
  .text-gray {
    color: #8e9296;
  }
}
.label {
  width: 48px;
  font-size: 12px;
  text-align: center;
  .icon-dot,
  .icon-circle {
    width: 12px;
    height: 12px;
  }
}
.num {
  width: 100px;
  flex: 0px 1 1;
  font-size: 35px;
}
.remain {
  margin: 8px 10px;
  line-height: 2;
  color: #fff;
  background-color: #818692;
}
body[data-dui-theme="dark"] {
  .item {
    .text-gray {
      color: var(--placeholder-color);
    }
  }
  .remain {
    background-color: var(--card-bg);
  }
}
.footer {
  margin: 100px 10px 30px;
  .dui-button {
    height: 55px;
    font-size: 16px;
  }
}
.yellow_btn {
  background-color: #307658;
  width: calc(50% - 15px);
  font-size: 12px;
  border-radius: 4px;
  display: flex;
  padding: 15px 10px;
}
.deep_blue_btn {
  background-color: #1d1f4e;
  width: calc(50% - 15px);
  font-size: 12px;
  border-radius: 4px;
  display: flex;
  padding: 15px 10px;
}
.loading_wrap{
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
}
.rate_btn{
  background-color: #fff;
  border-radius: 5px;
  margin-left: 20px;
  color: #333;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.rate_btn:last-child{
  margin-right: 20px;
}
</style>
