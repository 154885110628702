<template>
  <div class="header-bar">
    <div class="flex justify-between" :class="small ? 'align-start' : 'align-center'">
      <slot name="logo">
        <a class="logo margin-left" :class="{small}" href="/"></a>
      </slot>
      <!-- <slot name="right"></slot> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'header-bar',
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.header-bar {
  padding: 56px 12px 8px;
  --button-bg: transparent;

  .logo {
    display: block;
    width: 192px;
    height: 55px;
    color: inherit;
    font-size: 0;
    background: url("../../assets/images/logo.png") center / contain no-repeat;
    @media screen and (max-width: 414px) {
      &.small {
        width: 110px;
        height: 80px;
        background-image: url("../../assets/images/logo2.png");
      }
    }
  }
}
@media screen and (min-width: 415px) {
  .header-bar {
    position: sticky;
    top: 0;
    padding: 28px 12px 48px;
    z-index: 9;
  }
}
body[data-dui-theme="dark"] {
  .header-bar {
    --button-color: #fff;
    --button-bg: #2C2F4D;
    .logo {
      background: url("../../assets/images/logo-dark.png") center / contain no-repeat;
      @media screen and (max-width: 414px) {
        &.small {
          width: 110px;
          height: 80px;
          background-image: url("../../assets/images/logo2-dark.png");
        }
      }
    }
  }
}
body[data-bg="true"] {
  .header-bar {
    // background: var(--dui-BG) url("../../assets/images/bg.png") 0 60px / 100% auto no-repeat;
    background-color: var(--dui-BG);
  }
}
.icon-logo {
  margin: 8px 15px;
  width: 110px;
  height: 25px;
}
</style>
