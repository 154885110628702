<template>
  <div class="dui-page">
    <header-bar>
      <div slot="right" class="flex margin-top-xs" v-if="showToggle">
        <a class="bottom-button dui-button round" @click="gotoV1" href="https://v1.qkswap.io/">
          <icon type="exchange" class="margin-right-xs"></icon>
          切换至 v1.0
        </a>
      </div>
    </header-bar>
    <div class="container">
      <router-view name="tab" />
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { GLOBAL_CONFIGS } from '@/commons/const'

export default {
  name: 'Home',
  data () {
    return {
      showToggle: GLOBAL_CONFIGS.showToggle,
    }
  },
  mounted () {
    // 初始化数据
    this.getPoolNetwork()
  },
  methods: {
    ...mapActions(['getPoolNetwork']),
    gotoV1 () {
      window.location.href = 'https://v1.qkswap.io/'
    },
  },
}
</script>

<style lang="scss" scoped>
.dui-page {
  .dui-button {
    padding: 0;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--button-color);
    background-color: var(--button-bg);
  }
  .bottom-button {
    padding-left: 12px;
    padding-right: 12px;
  }
}
</style>
