import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import Dui from '@moohng/dui/src/components/dui'
import Plugin from '@/commons/vue-plugin'
import componentPlugin from '@/components'
import { Slider } from 'vant'
import 'vant/lib/slider/style'

Vue.use(Slider)
Vue.use(Dui)
Vue.use(Plugin)
Vue.use(componentPlugin)

Vue.config.productionTip = false
Vue.config.errorHandler = (err, vm, info) => {
  console.log(err, vm, info)
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
