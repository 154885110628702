import Icon from './Icon'
import HeaderBar from './HeaderBar'
import NavBar from './NavBar'
import Range from './Range'

export default {
  install (Vue) {
    Vue.component(Icon.name, Icon)
    Vue.component(HeaderBar.name, HeaderBar)
    Vue.component(NavBar.name, NavBar)
    Vue.component(Range.name, Range)
  },
}
