import { Decimal } from '@/commons/utils'
import ToleranceDialog from '../ToleranceDialog'
import base from './base'
import { ethers } from 'ethers'
import { PAIR_ABI, GLOBAL_CONFIGS } from '@/commons/const'
import Big from 'big.js'

export default {
  data () {
    return {
      fromInput: '',
      toInput: '',
      selectType: null,
      // toleranceValue: this.$util.getTolerance(),
      rate: null,
      lastOne: '', // from to

      submiting: false,

      baseAsset: null, // 基础资产
      fromAmount: 0, // 资金池剩余数量
      toAmount: 0,

      fromRemainAmount: null,
      toRemainAmount: null,
      fromContract: null, // from币种的合约
      toContract: null, // to币种的合约
      fromDecimals: 2, // from的精度
      toDecimals: 2, // to的精度
      authorizeFlagA: false, // from是否被授权
      authorizeFlagB: false, // to是否被授权
      from: {
        network: GLOBAL_CONFIGS.network,
      },
      to: {
        network: GLOBAL_CONFIGS.network,
      },
      pairContract: null, // 交易对地址
      myPoolList: [],
      lp: '', // 自己的股份
      loadingB: false,
      loadingA: false,
      isQa: 'not',
    }
  },
  components: {
    // CoinSelectButton,
    ToleranceDialog,
  },
  computed: {
    // 预计占比
    predictPer () {
      // 输入基础资产数量
      const baseAssetAmount = this.fromInput
      // 基础资产余额
      const baseAssetBlance = this.baseAsset
      if (baseAssetAmount && baseAssetBlance) {
        const val = Decimal.div(baseAssetAmount, Decimal.add(baseAssetAmount, baseAssetBlance))
        return Decimal.mul(val, 100)
      }
      return null
    },
  },
  created () {
    this.init()
  },
  mixins: [base],
  methods: {
    async init () {
      const { tokenA, tokenB, tokenBName, tokenAName, pairAddress } = this.$route.query
      this.from.contract_origin = tokenA || GLOBAL_CONFIGS.upperSymbol
      this.from.name = tokenAName
      this.to.contract_origin = tokenB || GLOBAL_CONFIGS.upperSymbol
      this.to.name = tokenBName
      this.fromContract = tokenA === '' ? null : this.getContract(this.from)
      this.fromDecimals = tokenA === '' ? 18 : await this.getDecimal(this.fromContract)
      this.toContract = tokenB === '' ? null : this.getContract(this.to)
      this.toDecimals = tokenB === '' ? 18 : await this.getDecimal(this.toContract)
      this.pairAddress = pairAddress
      if (pairAddress !== '') {
        this.createParirConstrct(pairAddress)
        this.getRate()
      }
      this.getBalance(this.from, 'fromRemainAmount')
      this.getBalance(this.to, 'toRemainAmount')
      if (tokenA === '') {
        this.authorizeFlagA = true
      }
      if (tokenB === '') {
        this.authorizeFlagB = true
      }
      this.isQa = this.isQA()
    },
    // 创建交易对合约
    createParirConstrct (address) {
      this.pairContract = new ethers.Contract(
        address,
        PAIR_ABI,
        this.signer,
      )
    },
    goto () {
      this.$router.push({ name: 'DecLiquidity', query: { tokenA: this.from.contract_origin, tokenB: this.to.contract_origin, tokenAName: this.from.name, tokenBName: this.to.name, pairAddress: this.pairAddress || '' } })
    },
    // 增加流动性
    async onSubmit (value) {
      if (this.fromInput === '' && this.toInput === '') {
        this.$toast('请输入数量')
        return
      }
      // 计算数量
      const fromDecimals = this.fromDecimals
      const toDecimals = this.toDecimals
      let fromAmount = 0
      let toAmount = 0
      if (this.isQa === 'from') {
        fromAmount = await this.getQaSaleAmount(this.fromContract, this.fromDecimals, this.fromInput)
        toAmount = Decimal.mul(this.toInput, ethers.BigNumber.from(10).pow(toDecimals))
      } else if (this.isQa === 'to') {
        fromAmount = Decimal.mul(this.fromInput, ethers.BigNumber.from(10).pow(fromDecimals))
        toAmount = await this.getQaSaleAmount(this.toContract, this.toDecimals, this.toInput)
      } else {
        fromAmount = Decimal.mul(this.fromInput, ethers.BigNumber.from(10).pow(fromDecimals))
        toAmount = Decimal.mul(this.toInput, ethers.BigNumber.from(10).pow(toDecimals))
      }
      // const fromAmount = Decimal.mul(this.fromInput, ethers.BigNumber.from(10).pow(fromDecimals))
      // const toAmount = Decimal.mul(this.toInput, ethers.BigNumber.from(10).pow(toDecimals))

      // 获取当前时间戳
      const timeStr = (Date.now()).toString().substring(0, 10)
      // 定义参数
      const tokenA = this.from.contract_origin
      const tokenB = this.to.contract_origin
      const amountADesired = String(Big(fromAmount).toFixed()).split('.')[0]
      const amountBDesired = String(Big(toAmount).toFixed()).split('.')[0]

      // 方差计算，没有这个提交就会失败
      const amountAMin = String(Decimal.mul(fromAmount, Decimal.sub(1, this.toleranceValue))).split('.')[0]
      const amountBMin = String(Decimal.mul(toAmount, Decimal.sub(1, this.toleranceValue))).split('.')[0]
      const to = this.address
      const deadline = parseInt(timeStr) + 600
      let response
      if (tokenA === GLOBAL_CONFIGS.upperSymbol || tokenB === GLOBAL_CONFIGS.upperSymbol) {
        const isFrom = tokenA === GLOBAL_CONFIGS.upperSymbol
        const token = isFrom ? tokenB : tokenA
        const amountTokenDesired = isFrom ? amountBDesired : amountADesired
        const amountTokenMin = isFrom ? amountBMin : amountAMin
        const amountQKI = isFrom ? amountADesired : amountBDesired
        const amountQKIMin = String(Decimal.mul(amountQKI, Decimal.sub(1, this.toleranceValue))).split('.')[0]
        console.log(amountQKIMin)
        console.log(token + ' ' + amountTokenDesired + ' ' + amountTokenMin + ' ' + amountQKIMin + ' ' + to + ' ' + deadline + ' ')
        const gasLimit = await this.getEstimateGas(() => this.routerConstract.estimateGas.addLiquidityQKI(token, amountTokenDesired, amountTokenMin, amountQKIMin, to, deadline, { value: amountQKI }))
        if (gasLimit === 0) {
          return
        }
        response = await this.toAsync(this.routerConstract.addLiquidityQKI(token, amountTokenDesired, amountTokenMin, amountQKIMin, to, deadline, {
          value: amountQKI,
          gasLimit,
          gasPrice: ethers.utils.parseUnits(String(this.gasPrice), 'gwei'),
        }))
      } else {
        const gasLimit = await this.getEstimateGas(() => this.routerConstract.estimateGas.addLiquidity(tokenA, tokenB, amountADesired, amountBDesired, amountAMin, amountBMin, to, deadline))
        if (gasLimit === 0) {
          return
        }
        response = await this.toAsync(this.routerConstract.addLiquidity(tokenA, tokenB, amountADesired, amountBDesired, amountAMin, amountBMin, to, deadline, {
          gasLimit,
          gasPrice: ethers.utils.parseUnits(String(this.gasPrice), 'gwei'),
        }))
      }
      const [error, res] = response
      if (this.doResponse(error, res)) {
        this.$toast('提交请求成功，等待区块确认')
        this.queryTransation(res.hash, () => {
          this.fromInput = ''
          this.toInput = ''
          // 重新获取lp的值
          if (this.pairAddress !== '') {
            this.getRate()
          }
          this.getBalance(this.from, 'fromRemainAmount')
          this.getBalance(this.to, 'toRemainAmount')
        })
      }
    },
    // 调用token的授权方法对路由地址进行授权
    async doAuthorize (coin, source) {
      if (this.loadingA || this.loadingB) {
        return
      }
      if (source === 'from' && this.authorizeFlagA) {
        return
      }
      if (source === 'to' && this.authorizeFlagB) {
        return
      }
      if (!coin.name) {
        this.$toast('请选择需要授权的币种')
        return
      }
      let amount
      let contract
      let decimals
      if (source === 'from') {
        amount = this.fromInput
        contract = this.fromContract
        decimals = this.fromDecimals
        this.loadingA = true
      } else {
        amount = this.toInput
        contract = this.toContract
        decimals = this.toDecimals
        this.loadingB = true
      }
      if (amount === '') {
        this.$toast('请输入需要授权的币种数量')
        this.loadingA = false
        this.loadingB = false
        return
      }
      const status = await this.checkAuthorize(contract, this.routerAddress, decimals, amount)
      if (status) {
        this.transationCallback(source)()
      } else {
        await this.authorize(amount, decimals, contract, this.routerAddress, this.transationCallback(source))
      }
    },
    // 区块链确认回调
    transationCallback (source) {
      return () => {
        if (source === 'from') {
          this.authorizeFlagA = true
          this.loadingA = false
        } else {
          this.authorizeFlagB = true
          this.loadingB = false
        }
      }
    },
    onFromInput (e) {
      const inputValue = e.target.value
      this.fromInput = this.$util.inputNumber(inputValue)
      if (this.from.contract_origin === GLOBAL_CONFIGS.upperSymbol) {
        this.authorizeFlagA = true
      } else {
        this.authorizeFlagA = false
      }
      if (!this.fromInput) {
        // this.toInput = ''
      } else {
        this.fromCalcTo()
      }
      this.lastOne = 'from'
    },
    onToInput (e) {
      const inputValue = e.target.value
      this.toInput = this.$util.inputNumber(inputValue)
      if (this.to.contract_origin === GLOBAL_CONFIGS.upperSymbol) {
        this.authorizeFlagB = true
      } else {
        this.authorizeFlagB = false
      }
      if (!this.toInput) {
      } else {
        this.toCalcFrom()
      }
      this.lastOne = 'to'
    },
    // 判断是否有Qa币种
    isQA () {
      if (this.fromContract != null && (this.fromContract.address.toUpperCase() === '0xa9ad3421c8953294367D3B2f6efb9229C690Cacb'.toUpperCase() ||
      this.fromContract.address.toUpperCase() === '0xC074cA48580EcEcB69F1ad4BF9c03F66bdE57A89'.toUpperCase())) {
        return 'from'
      }
      if (this.toContract != null && (this.toContract.address.toUpperCase() === '0xa9ad3421c8953294367D3B2f6efb9229C690Cacb'.toUpperCase() ||
      this.toContract.address.toUpperCase() === '0xC074cA48580EcEcB69F1ad4BF9c03F66bdE57A89'.toUpperCase())) {
        return 'to'
      }
      return 'not'
    },
    fromCalcTo () {
      if (this.rate) {
        this.toInput = Decimal.mul(this.fromInput, this.rate)
      } else {
        // this.toInput = ''
      }
      if (this.isQa === 'from') {
        this.getQaSaleAmount(this.fromContract, this.fromDecimals, this.fromInput)
      } else if (this.isQa === 'to') {
        this.getQaSaleAmount(this.toContract, this.toDecimals, this.toInput)
      }
    },
    toCalcFrom () {
      if (this.rate) {
        this.fromInput = Decimal.div(this.toInput, this.rate)
        // let amount = Decimal.div(this.toInput, this.rate)
        // amount = parseInt(Decimal.mul(amount, ethers.BigNumber.from(10).pow(this.fromDecimals)))
        // this.fromInput = Decimal.div(this.toInput, this.rate)
      } else {
        // this.fromInput = ''
      }
      if (this.isQa === 'from') {
        this.getQaSaleAmount(this.fromContract, this.fromDecimals, this.fromInput)
      } else if (this.isQa === 'to') {
        this.getQaSaleAmount(this.toContract, this.toDecimals, this.toInput)
      }
    },
  },
}
