<template>
  <div class="range margin-tb-xl" @click="onSelect">
    <div class="inner bg-blue round" :style="{left: `${left}px`}" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
      <div class="pop"><slot></slot></div>
    </div>
  </div>
</template>

<script>
import round from '@moohng/dan/lib/round'

export default {
  name: 'range',
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      rangeLength: 0,
      left: 0,

      touchStartX: 0,
      startX: 0,
    }
  },
  watch: {
    value (val) {
      this.left = this.rangeLength * val
    },
  },
  methods: {
    init () {
      const { width } = this.$el.getBoundingClientRect()
      const { width: innerWidth } = this.$el.firstChild.getBoundingClientRect()
      this.rangeLength = width - innerWidth
      // console.log(width, innerWidth)
      this.left = this.rangeLength * this.value
    },
    onTouchStart (e) {
      this.touchStartX = e.touches[0].pageX
      this.startX = this.left // 按下时滑块的位置
    },
    onTouchMove (e) {
      const moveX = e.touches[0].pageX
      const diffX = moveX - this.touchStartX // 本次移动的距离
      this.left = this.startX + diffX
      if (this.left > this.rangeLength) {
        this.left = this.rangeLength
      } else if (this.left < 0) {
        this.left = 0
      }
      this.$emit('input', round(this.left / this.rangeLength, 2))
    },
    onTouchEnd () {
      //
    },
    onSelect (e) {
      const pageX = e.pageX
      const leftX = e.currentTarget.getBoundingClientRect().left
      let diffX = pageX - leftX
      if (diffX > this.rangeLength) {
        diffX = this.rangeLength
      }
      if (diffX < 0) {
        diffX = 0
      }
      this.left = diffX
      this.$emit('input', round(this.left / this.rangeLength, 2))
    },
  },
  mounted () {
    this.init()
  },
  updated () {
    this.init()
  },
}
</script>

<style lang="scss" scoped>
.range {
  position: relative;
  height: 2px;
  background-color: #979797;
  .inner {
    position: absolute;
    top: 50%;
    width: 19px;
    height: 19px;
    transform: translate3d(0, -50%, 0);
    border: 2px solid #fff;
    .pop {
      position: absolute;
      left: 50%;
      color: var(--dui-FG);
      transform: translate3d(-50%, 100%, 0);
      white-space: nowrap;
    }
  }
}
body[data-dui-theme="dark"] {
  .range {
    background-color: #363636;
  }
}
</style>
