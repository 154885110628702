
import { Decimal, initEth } from '@/commons/utils'
import { FACTORY_ABI, ROUTER_ABI, NORMAL_ABI, PAIR_ABI, GLOBAL_CONFIGS } from '@/commons/const'
import { ethers } from 'ethers'
import Big from 'big.js'

export default {
  data () {
    return {
      factoryAddress: GLOBAL_CONFIGS.factory_address,
      factoryContract: null, // 工厂合约
      routerAddress: GLOBAL_CONFIGS.router_address,
      routerConstract: null, // 路由合约
      pairAddress: '',
      pairContract: null, // 交易对合约
      liquidityAble: false, // 是否具有流动性
      wqkiAddress: GLOBAL_CONFIGS.w_address, // wqki的合约地址
      loading: false,
      checkAuthorizeFlag: false,
      toleranceValue: this.$util.getTolerance(),
      gasPrice: '150',
    }
  },
  mixins: [initEth],
  watch: {
    signer () {
      this.factoryContract = new ethers.Contract(
        this.factoryAddress,
        FACTORY_ABI,
        this.signer,
      )
      this.routerConstract = new ethers.Contract(
        this.routerAddress,
        ROUTER_ABI,
        this.signer,
      )
    },
  },
  created () {
    this.factoryContract = new ethers.Contract(
      this.factoryAddress,
      FACTORY_ABI,
      this.signer,
    )
    this.routerConstract = new ethers.Contract(
      this.routerAddress,
      ROUTER_ABI,
      this.signer,
    )
    if (process.env.VUE_APP_PLATFORM === 'HECO') {
      this.getGasPrice()
    }
  },
  methods: {
    // 获取余额
    getBalance (coin, keyName) {
      const contractAddress = coin.contract_origin
      const netWorkName = coin.network.name
      if (contractAddress === '') {
        this.getQkiBalance(keyName)
      } else if (contractAddress !== GLOBAL_CONFIGS.upperSymbol) {
        this.getTokenBalance(contractAddress, netWorkName, keyName)
      } else {
        if (netWorkName === 'eth') {
          this.getEthBalance(keyName)
        } else {
          this.getQkiBalance(keyName)
        }
      }
    },

    getGasPrice () {
      var xmlhttp = null

      xmlhttp = new XMLHttpRequest()
      if (xmlhttp != null) {
        xmlhttp.onreadystatechange = () => {
          if (xmlhttp.readyState === 4) {
            if (xmlhttp.status === 200) {
              const res = JSON.parse(xmlhttp.response)
              if (Number(res.code) === 0) {
                this.gasPrice = Number((res.prices && res.prices.median) || 3) + 0.5
              } else {
                this.gasPrice = 3.1
              }
            } else {
              this.gasPrice = 3.1
            }
          }
        }
        xmlhttp.open('GET', process.env.VUE_APP_GAS_API + '/price/prediction', true)
        xmlhttp.send(null)
      } else {
        // showToaa('您当前的浏览器不支持XMLHttpRequest!')
      }
    },
    // 获取Eth主网
    async getEthBalance (keyName) {
      const provider = ethers.getDefaultProvider()
      const [error, balance] = await this.toAsync(
        provider.getBalance(this.address),
      )
      this.doResponse(error, balance, keyName)
    },
    // 获取主网qki的余额
    async getQkiBalance (keyName) {
      const [error, balance] = await this.toAsync(
        this.provider.getBalance(this.address),
      )
      this.doResponse(error, balance, keyName)
    },
    // 获取合约的余额
    async getTokenBalance (tokenAddress, netWorkName, keyName) {
      let contract
      if (netWorkName === 'eth') {
        contract = new ethers.Contract(tokenAddress, NORMAL_ABI, ethers.getDefaultProvider())
      } else {
        contract = new ethers.Contract(tokenAddress, NORMAL_ABI, this.signer)
      }
      const [, decimals] = await this.toAsync(contract.decimals())
      const [error, balance] = await this.toAsync(
        contract.balanceOf(this.address),
      )
      this.doResponse(error, balance, keyName, decimals)
    },
    // 获取合约的symbol
    async getTokenSymbol (tokenAddress) {
      const contract = new ethers.Contract(tokenAddress, NORMAL_ABI, this.signer)
      const [error, name] = await this.toAsync(contract.symbol())
      if (error == null) {
        return name
      }
    },
    // 计算qa的卖出数量
    async getQaSaleAmount (contract, decimal, saleAmount) {
      // 排除主网币
      if (contract == null) {
        return Decimal.mul(
          saleAmount,
          ethers.BigNumber.from(10).pow(decimal),
        )
      }
      if (contract.address.toUpperCase() === '0xa9ad3421c8953294367D3B2f6efb9229C690Cacb'.toUpperCase() ||
      contract.address.toUpperCase() === '0xC074cA48580EcEcB69F1ad4BF9c03F66bdE57A89'.toUpperCase()
      ) {
        // this.showQa = true
        const [error, amount] = await this.toAsync(contract.base())
        if (error == null) {
          const hex0 = ethers.utils.hexValue(amount)
          const base = this.hex2int(hex0)
          const amountWei = Decimal.mul(saleAmount, ethers.BigNumber.from(10).pow(decimal))
          const state = String(Decimal.div(amountWei, base)).split('.')[0]
          // this.qaSaleAmount = Decimal.div(Decimal.mul(state, base), ethers.BigNumber.from(10).pow(decimal))
          return Decimal.mul(state, base)
        }
      } else {
        // this.showQa = false
        // this.qaSaleAmount = 0
        return Decimal.mul(
          saleAmount,
          ethers.BigNumber.from(10).pow(decimal),
        )
      }
    },
    // 计算qa的to数量
    async getQaToSaleAmount (contract, saleAmount) {
      // 排除主网币
      if (contract == null) {
        return saleAmount
      }
      if (contract.address.toUpperCase() === '0xa9ad3421c8953294367D3B2f6efb9229C690Cacb'.toUpperCase() ||
      contract.address.toUpperCase() === '0xC074cA48580EcEcB69F1ad4BF9c03F66bdE57A89'.toUpperCase()
      ) {
        // this.showQa = true
        const [error, amount] = await this.toAsync(contract.base())
        if (error == null) {
          const hex0 = ethers.utils.hexValue(amount)
          const base = this.hex2int(hex0)
          const amountWei = saleAmount
          const state = String(Decimal.div(amountWei, base)).split('.')[0]
          console.log(base, state, amountWei.toString(), saleAmount.toString(), String(Decimal.mul(state, base)).split('.')[0])
          // this.qaSaleAmount = Decimal.div(Decimal.mul(state, base), ethers.BigNumber.from(10).pow(decimal))
          return String(Decimal.mul(state, base)).split('.')[0]
        }
      } else {
        return saleAmount
      }
    },
    /**
     *
     * @param {*} amount 授权的数量
     * @param {*} decimals 授权合约的精度
     * @param {*} contract 授权的合约
     * @param {*} needApproveContract 被授权的合约
     */
    async authorize (amount, decimals, contract, needApproveContract, fnCallback) {
      // 授权一个无限大的数值
      amount = amount === 0 ? amount : '1000000000000000000000000000000000000000000000000000000000000000000000000000'
      // amount = (ethers.BigNumber.from(10).pow(decimals)).mul(amount)
      const gasLimit = await this.getEstimateGas(() => contract.estimateGas.approve(needApproveContract, amount))
      if (gasLimit === 0) {
        return
      }
      const [error, res] = await this.toAsync(contract.approve(needApproveContract, amount), {
        gasLimit,
        gasPrice: ethers.utils.parseUnits(String(this.gasPrice), 'gwei'),
      })
      if (this.doResponse(error, res)) {
        this.queryTransation(res.hash, fnCallback)
      }
    },
    /**
     *
     * @param {*} contract 合约
     * @param {*} spenderAddress 被授权的地址
     * @param {*} decimals 合约精度
     * @param {*} compareAmount 被比较的值
     */
    async checkAuthorize (contract, spenderAddress, decimals, compareAmount) {
      const [error, res] = await this.toAsync(contract.allowance(this.address, spenderAddress))
      if (this.doResponse(error, res)) {
        const hex = ethers.utils.hexValue(res)
        const Value = Decimal.div(this.hex2int(hex), ethers.BigNumber.from(10).pow(decimals))
        if (Decimal.sub(Value, compareAmount) >= 0) {
          return true
        } else {
          // QUSDT每次都需要归0之后才能重新授权
          if ((contract.address).toLowerCase() === '0xdf0e293cc3c7ba051763ff6b026da0853d446e38' && String(Value) !== '0') {
            await this.authorize(
              0,
              decimals,
              contract,
              this.routerAddress,
              () => {},
            )
          }
          return false
        }
      } else {
        return false
      }
    },
    // 获取精度
    async getDecimal (contract) {
      const [, decimals] = await this.toAsync(contract.decimals())
      return decimals
    },
    // 创建erc20合约
    getContract (coin) {
      const contractAddress = coin.contract_origin
      const netWorkName = coin.network.name
      let contract
      if (netWorkName === 'eth') {
        contract = new ethers.Contract(contractAddress, NORMAL_ABI, ethers.getDefaultProvider())
      } else {
        contract = new ethers.Contract(contractAddress, NORMAL_ABI, this.signer)
      }
      return contract
    },
    // 是否具有流动性，如果没有流动性，新增流动性，有则添加流动性
    async calcLiquidity (fromCoin, toCoin) {
      if (fromCoin.name && toCoin.name) {
        this.loading = true
        const fromContract = fromCoin.contract_origin || this.wqkiAddress
        const toContract = toCoin.contract_origin || this.wqkiAddress
        const [error, res] = await this.toAsync(this.factoryContract.getPair(fromContract, toContract))
        this.loading = false
        if (error == null) {
          if (res === ethers.constants.AddressZero) {
            this.liquidityAble = 'create'
            this.pairAddress = ''
            this.pairContract = null
          } else {
            this.pairAddress = res
            this.pairContract = new ethers.Contract(
              res,
              PAIR_ABI,
              this.signer,
            )
            this.liquidityAble = 'add'
          }
        } else {
          this.liquidityAble = false
          this.pairAddress = ''
          this.pairContract = null
        }
      } else {
        this.liquidityAble = false
        this.pairAddress = ''
        this.pairContract = null
      }
    },
    // 关闭公差弹框
    onConfirm (value) {
      this.toleranceValue = value
      this.$refs.toleranceDialog.close()
    },
    // 获得流动池amount
    async getLiquidityAmount (tokenA, tokenB, tokenADecimal, tokenBDecimal, pairContractName = 'pairContract') {
      if (tokenA.name && tokenB.name) {
        let decimal0 = 2
        let decimal1 = 2
        const [err0, token0] = await this.toAsync(this[pairContractName].token0())
        const fromContract = tokenA && ((tokenA.contract_origin).toUpperCase() === GLOBAL_CONFIGS.upperSymbol || (tokenA.contract_origin) === '') ? this.wqkiAddress.toUpperCase() : tokenA && (tokenA.contract_origin).toUpperCase()
        if (err0 == null) {
          // 对token0，和token1分别进行精度赋值
          if (fromContract === token0.toUpperCase()) {
            decimal0 = tokenADecimal
            decimal1 = tokenBDecimal
          } else {
            decimal0 = tokenBDecimal
            decimal1 = tokenADecimal
          }
        }
        // 获取区块链上这个交易对的数量
        const [err2, res] = await this.toAsync(this[pairContractName].getReserves())
        // 计算返回的值
        if (err2 == null) {
          const hex0 = ethers.utils.hexValue(res[0])
          const Value0 = Big(this.hex2int(hex0)) / ethers.BigNumber.from(10).pow(decimal0)
          const hex1 = ethers.utils.hexValue(res[1])
          const Value1 = Big(this.hex2int(hex1)) / ethers.BigNumber.from(10).pow(decimal1)
          let fromAmount = 0
          let toAmount = 0
          // 使用from类型的币种来计算预计占比，所以获取from类型的币种的总量
          if (fromContract === token0.toUpperCase()) {
            fromAmount = Big(Value0).toFixed()
            toAmount = Big(Value1).toFixed()
          } else {
            fromAmount = Big(Value1).toFixed()
            toAmount = Big(Value0).toFixed()
          }
          return {
            fromAmount,
            toAmount,
          }
        }
        return {
          fromAmount: 0,
          toAmount: 0,
        }
      }
      return {
        fromAmount: 0,
        toAmount: 0,
      }
    },
    // 获得gas
    async getEstimateGas (fn) {
      const [err, res] = await this.toAsync(fn())
      if (this.doResponse(err, res)) {
        const hex = ethers.utils.hexValue(res)
        const Value = this.hex2int(hex)
        return String(Decimal.mul(Value, 1.5)).split('.')[0]
      } else {
        return 0
      }
    },
  },
}
