<template>
  <div>
    <button class="dui-button" @click="$refs.coinDialog.open()">
      <div v-if="coin.icon" class="icon bg-img round" v-src="coin.icon"></div>
      <span class="upper-case">{{coin.name || buttonText || $t('selectPass')}}</span>
      <icon class="margin-left-xs" type="down"></icon>
    </button>
    <dui-dialog ref="coinDialog">
      <NetworkDialog v-if="type === 'network'" :options="options" :current="coin" @close="$refs.coinDialog.close()" @select="onSelect" />
      <CoinDialog v-else :options="options" :disable="disable" @close="$refs.coinDialog.close()" @select="onSelect" />
    </dui-dialog>
  </div>
</template>

<script>
import CoinDialog from './CoinDialog'
import NetworkDialog from './NetworkDialog'

export default {
  components: {
    CoinDialog,
    NetworkDialog,
  },
  props: {
    coin: Object,
    buttonText: String,
    disable: {
      type: Array,
      default: () => [],
    },
    options: Object,
    type: {
      type: String,
      default: 'coin',
    },
  },
  methods: {
    onSelect (coin, index) {
      this.$refs.coinDialog.close()
      this.$emit('select', coin, index)
    },
  },
}
</script>

<style lang="scss" scoped>
.dui-button {
  padding-left: 16px;
  padding-right: 16px;
  height: 42px;
  color: var(--dui-FG);
  background-color: var(--button-bg);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    margin-right: 12px;
    width: 23px;
    height: 23px;
    background-color: transparent;
  }
}
</style>
