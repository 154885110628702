<template>
  <div class="add-liq">
    <div class="card">
      <nav-bar>{{$t('addLiq')}}</nav-bar>
      <div class="item">
        <div class="label">{{from.contract_origin}}</div>
        <div class="flex justify-between align-center padding-lr-sm">
          <input class="num margin-lr-xs" v-model="fromInput" placeholder="0.0" @input="onFromInput" />
        </div>
      </div>
      <div class="text-center text-sm upper-case" v-show="fromRemainAmount !== null">可用：{{fromRemainAmount}} {{from.name}}</div>
      <div class="text-center">
        <icon type="add"></icon>
      </div>
      <div class="item">
        <div class="label">{{to.contract_origin}}</div>
        <div class="flex justify-between align-center padding-lr-sm">
          <input class="num margin-lr-xs" v-model="toInput" placeholder="0.0" @input="onToInput" />
        </div>
      </div>
      <div class="text-center text-sm upper-case" v-show="toRemainAmount !== null">可用：{{toRemainAmount}} {{to.name}}</div>
    </div>
    <!-- <div v-show="showQa" class="padding-lr margin-tb-sm flex justify-between text-md">
      <span class="text-gray upper-case">QA卖出数量</span>
      <span>{{qaSaleAmount}}</span>
    </div> -->
    <div class="padding flex justify-between align-center text-md">
      <span class="text-gray">{{$t('tolerance')}}</span>
      <a @click="$refs.toleranceDialog.open()">{{toleranceValue|mul(100)}}%<icon class="text-gray margin-left-xs" type="edit" /></a>
    </div>
    <div v-show="predictPer" class="padding flex justify-between text-md">
      <span class="text-gray upper-case">{{$t('predictPer')}}</span>
      <span>{{predictPer}}%</span>
    </div>
    <div class="margin-lr-sm margin-top-lg">{{$t('yourLiq')}}</div>
    <a class="item1 margin-tb padding-lr" @click="goto">
      <div class="relative flex justify-between">
        <span class="margin-left-xs upper-case">{{from.name}} / {{to.name}}</span>
        <span>{{lp}}</span>
      </div>
    </a>
    <div class="margin-lr-sm margin-top-lg">销毁lp</div>
    <a class="item1 margin-tb padding-lr" @click="gotoDestory">
      <div class="relative flex justify-between">
        <span class="margin-left-xs upper-case">{{from.name}} / {{to.name}}</span>
        <span>{{lp}}</span>
      </div>
    </a>
    <div class="footer" v-if="authorizeFlagA && authorizeFlagB">
      <button class="dui-button block bg-blue" @click="onSubmit">{{$t('confirmAdd')}}</button>
    </div>
    <div class="footer flex_h_between" v-else>
      <button :class="['dui-button', 'flex1', 'mr_15', authorizeFlagA ? 'grey-blue':'bg-blue']" @click="doAuthorize(from, 'from')"><img :src="require('../../assets/images/loading.gif')" alt=""  v-if="loadingA" class="loading"/>{{$t('authorize')}} {{(from.name || '').toUpperCase()}}</button>
      <button :class="['dui-button' ,'flex1', authorizeFlagB ? 'grey-blue':'bg-blue']" @click="doAuthorize(to, 'to')"><img :src="require('../../assets/images/loading.gif')" alt=""  v-if="loadingB" class="loading"/>{{$t('authorize')}} {{(to.name || '').toUpperCase()}}</button>
    </div>
    <dui-dialog ref="toleranceDialog">
      <ToleranceDialog @close="$refs.toleranceDialog.close()" @confirm="onConfirm" />
    </dui-dialog>
  </div>
</template>

<script>
import { Decimal } from '@/commons/utils'
import { ethers } from 'ethers'
import liquidity from '../commons/mixins/liquidity'
import { GLOBAL_CONFIGS } from '@/commons/const'

export default {
  data () {
    return {
      lp: '', // 自己的股份
    }
  },
  mixins: [liquidity],
  methods: {
    // 获取交易对池总量， 计算比例
    async getRate () {
      const { fromAmount, toAmount } = await this.getLiquidityAmount(
        this.from,
        this.to,
        this.fromDecimals,
        this.toDecimals,
      )
      this.fromAmount = fromAmount
      this.toAmount = toAmount
      this.baseAsset = fromAmount
      this.rate = Decimal.div(toAmount, fromAmount)
      // 获取自己的lp TODO: 这里不需要计算精度
      const [, banlance] = await this.toAsync(this.pairContract.balanceOf(this.address))
      const hex2 = ethers.utils.hexValue(banlance)
      const Value2 = this.hex2int(hex2)
      this.lp = Value2
      if (this.lastOne === 'from') {
        this.fromCalcTo()
      } else if (this.toInput) {
        this.toCalcFrom()
      }
      this.timer = setTimeout(() => {
        this.getRate()
      }, 10000)
    },
    // 跳转删除流动性
    goto () {
      const fromContract = this.from.contract_origin === GLOBAL_CONFIGS.upperSymbol ? '' : this.from.contract_origin
      const toContract = this.to.contract_origin === GLOBAL_CONFIGS.upperSymbol ? '' : this.to.contract_origin
      this.$router.push({ name: 'DecLiquidity', query: { tokenA: fromContract, tokenB: toContract, tokenAName: this.from.name, tokenBName: this.to.name, pairAddress: this.pairAddress || '' } })
    },
    gotoDestory () {
      const fromContract = this.from.contract_origin === GLOBAL_CONFIGS.upperSymbol ? '' : this.from.contract_origin
      const toContract = this.to.contract_origin === GLOBAL_CONFIGS.upperSymbol ? '' : this.to.contract_origin
      this.$router.push({ name: 'destoryLiquidity', query: { tokenA: fromContract, tokenB: toContract, tokenAName: this.from.name, tokenBName: this.to.name, pairAddress: this.pairAddress || '' } })
    },
  },
  destroyed () {
    clearTimeout(this.timer)
  },
}
</script>

<style lang="scss" scoped>
.card {
  margin: 16px 10px;
  padding-bottom: 15px;
  background-color: var(--card-bg2);
  border-radius: 10px;
}
.item {
  margin: 6px 10px;
  padding: 16px 4px;
  background-color: var(--card-bg);
  border-radius: 10px;
}
.label {
  margin-left: 18px;
  color: var(--num-color);
  font-size: 12px;
}
.num {
  width: 100px;
  flex: 0px 1 1;
  font-size: 35px;
}
.icon-add {
  width: 10px;
  height: 12px;
}
.footer {
  margin: 100px 10px 10px;
  .dui-button {
    height: 55px;
    font-size: 16px;
    border-radius: 10px;
  }
}
body[data-dui-theme="dark"] {
  .item {
    --card-bg: #2B2D61;
  }
}
.item1 {
  display: block;
  margin: 12px 10px;
  padding-top: 30px;
  padding-bottom: 18px;
  // height: 80px;
  background: var(--card-bg2);
  box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 0.0299388);
  border-radius: 10px;
  font-size: 16px;
  .label {
    position: absolute;
    right: 0;
    bottom: 90%;
    font-size: 12px;
    color: #979ab6;
    white-space: nowrap;
  }
  .contract {
    font-size: 12px;
    color: #979ab6;
  }
}
</style>
