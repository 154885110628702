<template>
  <div class="pool margin-top-lg">
    <div class="item">
        <div class="label">{{$t('selectToken')}}</div>
        <div class="flex justify-between align-center padding-lr-sm">
          <input class="num margin-lr-xs" v-model="fromInput" :placeholder="$t('selectToken')" @input="onInput" />
          <CoinSelectButton :disable="[to.id]" :coin="from" @select="onFromSelect" />
        </div>
      </div>
      <div class="item">
        <div class="label">{{$t('selectToken')}}</div>
        <div class="flex justify-between align-center padding-lr-sm">
          <input class="num margin-lr-xs" v-model="toInput" :placeholder="$t('selectToken')" @input="onInput" />
          <CoinSelectButton :disable="[from.id]" :coin="to" @select="onToSelect" />
        </div>
      </div>
    <a class="card bg-blue flex justify-center align-center" @click="goto">
      <img :src="require('../../assets/images/loading.gif')" alt=""  v-if="loading" class="loading"/>
      {{liquidityAble === 'add' ? $t('addLiq') : $t('createLiq')}}
    </a>
    <a class="card yellow-blue flex justify-center align-center" v-if="from.name && to.name" @click="copy">
      {{$t('shareLip')}}
    </a>
  </div>
</template>

<script>
import { h5Copy } from '@/commons/utils'
import CoinSelectButton from '../commons/CoinSelectButton'
import { FACTORY_ABI, GLOBAL_CONFIGS } from '@/commons/const'
import { ethers } from 'ethers'
import base from '../commons/mixins/base'

export default {
  data () {
    return {
      from: {},
      to: {},
      fromInput: '',
      toInput: '',
      liquidityAble: false, // add为添加流动性，create为新增流动性
      factoryAddress: GLOBAL_CONFIGS.factory_address,
      factoryContract: null, // 工厂合约
      loading: false,
      // wqkiAddress: '0x835f6df988b6f51c9477d49e96adbbc644ba97a2', // wqki的合约地址
    }
  },
  components: {
    CoinSelectButton,
  },
  mixins: [base, h5Copy],
  created () {
    this.factoryContract = new ethers.Contract(
      this.factoryAddress,
      FACTORY_ABI,
      this.signer,
    )
    const { tokenA, tokenB, tokenBName, tokenAName } = this.$route.query
    if (tokenAName) {
      this.from = {
        contract_origin: tokenA,
        network: {
          name: GLOBAL_CONFIGS.network,
        },
        name: tokenAName,
      }
      this.to = {
        contract_origin: tokenB,
        network: {
          name: GLOBAL_CONFIGS.network,
        },
        name: tokenBName,
      }
    }
  },
  watch: {
    network (value) {
      if (value) {
        this.init(value)
      }
    },
    async from (newCoin) {
      this.fromInput = newCoin.contract_origin || GLOBAL_CONFIGS.upperSymbol
      this.calcLiquidity(newCoin, this.to)
    },
    async to (newCoin) {
      this.toInput = newCoin.contract_origin || GLOBAL_CONFIGS.upperSymbol
      this.calcLiquidity(newCoin, this.from)
    },
  },
  methods: {
    // ...mapActions(['getPool']),
    init (value) {
      this.from = value.symbols?.[0]
    },
    // 来源选择回调
    async onFromSelect (coin) {
      this.from = coin
      this.liquidityAble = false
    },
    // 去源选择回调
    onToSelect (coin) {
      this.to = coin
      this.liquidityAble = false
    },
    onInput (e) {
      if (e.target.value !== '' && e.target.value.length !== 42) {
        this.$toast('请正确输入token地址')
      }
    },
    goto () {
      if (this.from?.name && this.to?.name) {
        if (this.liquidityAble === false) {
          this.$toast('正在确认交易对请稍后再试！')
          return
        }
        this.$router.push({ name: this.liquidityAble === 'create' ? 'CreateLiquidity' : 'AddLiquidity', query: { tokenA: this.from.contract_origin, tokenB: this.to.contract_origin, tokenAName: this.from.name, tokenBName: this.to.name, pairAddress: this.pairAddress || '' } })
      } else {
        if (this.liquidityAble === false) {
          this.$toast('请选择需要操作的交易对！')
        }
      }
    },
    copy () {
      if (this.from?.name && this.to?.name) {
        if (this.liquidityAble === false) {
          this.$toast('正在确认交易对请稍后再试！')
          return
        }
        const origin = (window.location.href || '').split('#')[0]
        const query = {
          tokenA: this.from.contract_origin,
          tokenB: this.to.contract_origin,
          tokenAName: this.from.name,
          tokenBName: this.to.name,
          pairAddress: this.pairAddress || '',
        }
        const queryArr = []
        for (const key in query) {
          queryArr.push(key + '=' + query[key])
        }
        const url = origin + '#/' + (this.liquidityAble === 'create' ? 'liquidity/create' : 'liquidity/add') + '?' + queryArr.join('&')
        this.h5Copy(url)
      } else {
        this.$toast('请选择需要操作的交易对！')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  margin: 12px 10px;
  height: 80px;
  box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 0.0299388);
  border-radius: 10px;
  font-size: 17px;
}
.item {
  margin: 15px 10px;
  padding: 16px 4px;
  background-color: var(--card-bg);
  border-radius: 10px;
}
.label {
  margin-left: 18px;
  color: var(--num-color);
  font-size: 12px;
}
.num {
  width: 100px;
  flex: 0px 1 1;
  color: var(--num-color);
  font-size: 12px;
}
.item {
  .contract {
    font-size: 12px;
    color: #979ab6;
  }
}

</style>
